import React, { Component } from 'react';
import Waypoint from 'react-waypoint';

import { wpApi, service } from '../../Service';
import Layout from '../../Containers/Layout/Layout';
import Hero from '../../Containers/Hero/Hero';
import InstagramLab from '../../Compoments/Instagram/Instagram';

const _w = window;

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      homeField: null,
      latestProject: null,
      eventPosts: null,
      socialData: null,
      animateTitle0: '',
      animateTitle1: '',
      animateTitle2: '',
      animateProject0: '',
      animateProject1: '',
      animateProject2: '',
      animateEvent0: '',
      animateEvent1: '',
      animateEvent2: '',
      animateVideo0: '',
      animateVideo1: '',
      animateIg: '',
      animateHero: '',
    }
  }

  componentWillMount() {
    wpApi.getPageDataById(_w.homeId, data => this.setState({ homeField: data.acf }));
    this._getLatestProject('project');
    this._getLatesEvent('event');
    wpApi.getPagesData(data => this.setState({ socialData: data.filter(page => page.title.rendered === '設定')[0].acf }));
  }

  componentDidMount() {
    setTimeout(() => {
      this._handleEnterAnimate('Hero');
    }, 500);
  }

  _getLatestProject = slug => {
    const _filterPostIdBySlug = data => {
      const _categorieId = data.filter(obj => { return obj.slug === slug })[0].id;

      wpApi.getPostsByCategorieId(_categorieId, data => this.setState({
        latestProject: data.sort((a, b) => { return new Date(b.date) - new Date(a.date) })
      }));
    }

    wpApi.getCategoriesData(_filterPostIdBySlug);
  }

  _getLatesEvent = postType => {
    wpApi.getPostDataByType(postType, data => this.setState({
      eventPosts: data.sort((a, b) => { return new Date(b.date) - new Date(a.date) })
    }));
  }

  _handleEnterAnimate = name => {
    if (this.state[`animate${name}`] === '') {
      this.setState({ [`animate${name}`]: ' a-animated' });
    }
  }

  render() {
    return (
      <Layout className="content-wrap" _temp="home">
        <Hero className={` a-nonOpacity${this.state.animateHero}`} _field={this.state.homeField} />

        {/* 專題企劃 */}
        <div className="content-rowBg">
          <div className="layout-container">
            <div className="content-row content-row_home">
              <Waypoint onEnter={() => this._handleEnterAnimate('Title0')} bottomOffset="50px">
                <h1 className={`content-title a-nonOpacity${this.state.animateTitle0}`}>專題企劃</h1>
              </Waypoint>

              <h6 className={`content-subtitle a-nonOpacity${this.state.animateTitle0}`}>projects</h6>

              {
                this.state.latestProject ? (
                  this.state.latestProject.map((post, index) => {
                    return index < 3 ? (
                      <Waypoint key={`latestProject${post.date}`} onEnter={() => this._handleEnterAnimate(`Project${index}`)} bottomOffset="250px">
                        <div className={`content-col content-col_projects a-nonOpacity${this.state[`animateProject${index}`]}`}>
                          {
                            post._embedded['wp:featuredmedia'] ? <div className="content-photo" style={{ backgroundImage: `url(${post._embedded['wp:featuredmedia'][0].source_url})` }} /> : <div className="content-photo" style={{ backgroundColor: '#efefef' }} />
                          }

                          <div className="content-info">
                            <p className="content-time">{service.getTimeByString(post.date, '.')}</p>

                            <h3 className="content-colTitle" dangerouslySetInnerHTML={{ __html: post.title.rendered }} />

                            <div className="content-preview" dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />

                            <div className="content-readmore">
                              <a className="link_black" href={post.link}>READ MORE</a>
                            </div>
                          </div>
                        </div>
                      </Waypoint>
                    ) : null
                  })
                ) : null
              }
            </div>
          </div>
        </div>
        {/* 最新活動 */}
        <div className="content-rowBg content-rowBg_event">
          <div className="layout-container">
            <div className="content-row">
              <Waypoint onEnter={() => this._handleEnterAnimate('Title1')} bottomOffset="50px">
                <h1 className={`content-title a-nonOpacity${this.state.animateTitle1}`}>最新活動</h1>
              </Waypoint>

              <h6 className={`content-subtitle a-nonOpacity${this.state.animateTitle1}`}>events</h6>

              <div className="content-eventWrap">
                {
                  this.state.eventPosts ? (
                    this.state.eventPosts.map((event, index) => {
                      return index < 3 ? (
                        <Waypoint key={`eventPosts${event.date}`} onEnter={() => this._handleEnterAnimate(`Event${index}`)} bottomOffset="250px">
                          <div className={`content-col content-col_event a-nonOpacity${this.state[`animateEvent${index}`]}`}>
                            <a className="link_default" href={event.link}>
                              {
                                event._embedded['wp:featuredmedia'] ? <div className="content-photo" style={{ backgroundImage: `url(${event._embedded['wp:featuredmedia'][0].source_url})` }} /> : <div className="content-photo" style={{ backgroundColor: '#efefef' }} />
                              }
                            </a>

                            <div className="content-info">
                              <h3 className="content-colTitle" dangerouslySetInnerHTML={{ __html: event.title.rendered }} />

                              <div className="content-preview content-preview_event" dangerouslySetInnerHTML={{ __html: event.excerpt.rendered }} />

                              <a className="content-toknow" href={event.link}>/ 我要暸解 /</a>
                            </div>
                          </div>
                        </Waypoint>
                      ) : null
                    })
                  ) : null
                }
              </div>

              <Waypoint onEnter={() => this._handleEnterAnimate('Title2')} bottomOffset="50px">
                <div className={`content-readmore content-readmore_center a-nonOpacity${this.state.animateTitle2}`}>
                  <a className="link_black" href="/活動行程/">LOAD MORE</a>
                </div>
              </Waypoint>
            </div>
          </div>
        </div>
        {/* 理念影片 */}
        <div className="content-rowBg">
          <div className="layout-container">
            <div className="content-row">
              {
                this.state.homeField ? (
                  <div className="content-col">
                    <Waypoint onEnter={() => this._handleEnterAnimate(`Video0`)} bottomOffset="250px">
                      <div className={`content-video a-nonOpacity${this.state.animateVideo0}`}>
                        <iframe width="100%" height="100%" src={this.state.homeField.home_video_code} frameBorder="0" allow="autoplay; encrypted-media" title="理念影片" allowFullScreen></iframe>
                      </div>
                    </Waypoint>

                    <Waypoint onEnter={() => this._handleEnterAnimate(`Video1`)} bottomOffset="250px">
                      <div className={`content-info a-nonOpacity${this.state.animateVideo1}`}>
                        <h3 className="content-colTitle content-colTitle_full">{this.state.homeField.home_video_title}</h3>

                        <p className="content-preview content-preview_full">{this.state.homeField.home_video_desc}</p>

                        <div className="content-readmore content-readmore_left">
                          <a className="link_black" href={this.state.homeField.home_video_link}>LEARN MORE</a>
                        </div>
                      </div>
                    </Waypoint>
                  </div>
                ) : null
              }
            </div>
          </div>
        </div>
        {/* INSTAGRAM */}
        <Waypoint onEnter={() => this._handleEnterAnimate(`Ig`)} bottomOffset="250px">
          <div className={`content-rowBg content-rowBg_ig a-nonOpacity${this.state.animateIg}`}>
            <div className="layout-container">
              <div className="content-row">
                <h1 className="content-igTitle">INSTAGRAM</h1>
                {
                  this.state.socialData && this.state.socialData.ig_token ? <InstagramLab _className="content-instagram" _accessToken={this.state.socialData.ig_token} _limit={6} /> : null
                }
              </div>
            </div>
          </div>
        </Waypoint>
      </Layout>
    );
  }
}

export default Home;
