import React, { Component } from 'react';
import { Popover } from 'antd';

import { service } from '../../Service';
import iconFb from '../../images/Icon/fb_b.svg';
// import iconIg from '../../images/Icon/ig_b.svg';
import iconShare from '../../images/Icon/share_b.svg';
import iconLine from '../../images/Icon/line_b.svg';
import iconMail from '../../images/Icon/mail_b.svg';
import iconPrinter from '../../images/Icon/printer_b.svg';
import iconTwitter from '../../images/Icon/twitter_b.svg';
import iconWechat from '../../images/Icon/wechat_b.svg';
import iconWhatApp from '../../images/Icon/whatApp_b.svg';
import './ShareList.scss';

const _w = window;
const _href = _w.location.href;

class ShareList extends Component {
  render() {
    const { _title } = this.props;
    const MobilePop = (
      <div>
        <a className="ShareList-socialIcon" href={`//social-plugins.line.me/lineit/share?url=${_href}`} target="_blank" rel="noopener noreferrer">
          <img src={iconLine} alt="iconLine" />
        </a>

        <a className="ShareList-socialIcon" href={`//www.addtoany.com/add_to/wechat?linkurl=${_href}`} target="_blank" rel="noopener noreferrer">
          <img src={iconWechat} alt="iconWechat" />
        </a>
        
        <a className="ShareList-socialIcon" href={`//twitter.com/share?text=${_title}&url=${_href}`} target="_blank" rel="noopener noreferrer">
          <img src={iconTwitter} alt="iconTwitter" />
        </a>

        <a className="ShareList-socialIcon" href={`//api.whatsapp.com/send?text=${_href}`} target="_blank" rel="noopener noreferrer">
          <img src={iconWhatApp} alt="iconWhatApp" />
        </a>

        <a className="ShareList-socialIcon" href={`mailto:?subject=空間母語 - ${_title}&body=${_title} : 文章推薦連結 ${_href}`}>
          <img src={iconMail} alt="iconMail" />
        </a>

        <a className="ShareList-socialIcon" href="javascript:;" onClick={() => service.printDiv('printContent')}>
          <img src={iconPrinter} alt="iconPrinter" />
        </a>
      </div>
    );

    return (
      <div className="ShareList-social">
        <a className="ShareList-socialIcon" href={`//www.facebook.com/sharer/sharer.php?u=${_href}`} target="_blank" rel="noopener noreferrer">
          <img src={iconFb} alt="iconFb" />
        </a>

        <Popover content={MobilePop} title="分享" trigger="click">
          <a className="ShareList-socialIcon ShareList-socialIcon_mobile" href="javascript:;">
            <img src={iconShare} alt="iconShare" />
          </a>
        </Popover>

        <a className="ShareList-socialIcon ShareList-socialIcon_desktop" href={`//social-plugins.line.me/lineit/share?url=${_href}`} target="_blank" rel="noopener noreferrer">
          <img src={iconLine} alt="iconLine" />
        </a>

        <a className="ShareList-socialIcon ShareList-socialIcon_desktop" href={`//www.addtoany.com/add_to/wechat?linkurl==${_href}`} target="_blank" rel="noopener noreferrer">
          <img src={iconWechat} alt="iconWechat" />
        </a>

        <a className="ShareList-socialIcon ShareList-socialIcon_desktop" href={`//twitter.com/share?text=${_title}&url=${_href}`} target="_blank" rel="noopener noreferrer">
          <img src={iconTwitter} alt="iconTwitter" />
        </a>

        <a className="ShareList-socialIcon ShareList-socialIcon_desktop" href={`//api.whatsapp.com/send?text=${_href}`} target="_blank" rel="noopener noreferrer">
          <img src={iconWhatApp} alt="iconWhatApp" />
        </a>

        <a className="ShareList-socialIcon ShareList-socialIcon_desktop" href={`mailto:?subject=空間母語 - ${_title}&body=文章推薦連結 - ${_title} :  ${_href}`}>
          <img src={iconMail} alt="iconMail" />
        </a>

        <a className="ShareList-socialIcon ShareList-socialIcon_desktop" href="javascript:;" onClick={() => service.printDiv('printContent')}>
          <img src={iconPrinter} alt="iconPrinter" />
        </a>
      </div>
    );
  }
}

export default ShareList;