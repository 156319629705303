import React, { Component } from 'react';
import axios from 'axios';
import { Modal } from 'antd';

class InstagramLab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      InstagramData: null,
    }
  }

  componentWillMount() {
    const { _accessToken, _limit } = this.props;

    if(_accessToken){
      axios.get(`https://api.instagram.com/v1/users/self/media/recent/?access_token=${_accessToken}${_limit ? `&count=${_limit}` : ''}`).then(response => {
        if (response.status === 200) {
          _postSuccess(response.data.data);
        } else {
          _postError(response.data.MessageCode, response.data.Message);
        }
      }).catch(error => {
        if (error.response) {
          _postError(error.status, error.statusText);
        } else {
          console.log('Error', error);
        }
      });
    
      const _postSuccess = data => {
        this.setState({ InstagramData: data })
      }
    
      const _postError = (code, message) => {
        Modal.error({
          title: 'get PostType By Name error',
          content: `${code}, ${message}`,
          maskClosable: true,
          okText: '確定',
        });
      }
    }
  }

  render () {
    const { _className } = this.props;

    return (
      <div className={_className}>
        {
          this.state.InstagramData ? (
            this.state.InstagramData.map(obj => {
              return (
                <a className="instagram-link" href={obj.link} target="_blank" rel="noopener noreferrer" key={`InstagramLab${obj.id}`}>
                  <img src={obj.images.standard_resolution.url} alt={obj.tags.toString()} />
                </a>
              )
            })
          ) : null
        }
      </div>
    )
  }
};

export default InstagramLab;