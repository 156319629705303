import axios from 'axios';
import { Modal } from 'antd';
import * as parseUrl from '../Global/parseUrl';

const w = window;

export const getSinglePostDataById = (id, callBack) => {
  axios.get(`${parseUrl.v2ApiUrl}posts/${id}?_embed`, w.previewId && w.previewId !== '' ? {
    headers: { 'X-WP-Nonce': w.previewId }
  } : {}).then(response => {
    if (response.status === 200) {
      _postSuccess(response.data);
    } else {
      _postError(response.data.MessageCode, response.data.Message);
    }
  }).catch(error => {
    if (error.response) {
      _postError(error.status, error.statusText);
    } else {
      console.log('Error', error);
    }
  });

  const _postSuccess = data => {
    callBack(data);
  }

  const _postError = (code, message) => {
    Modal.error({
      title: 'get PostData By Title error',
      content: `${code}, ${message}`,
      maskClosable: true,
      okText: '確定',
    });
  }
}

export const getPageFieldById = (id, callBack) => {
  axios.get(`${parseUrl.fieldApiUrl}pages/${id}?_embed`, w.previewId && w.previewId !== '' ? {
    headers: { 'X-WP-Nonce': w.previewId }
  } : {}).then(response => {
    if (response.status === 200) {
      _postSuccess(response.data);
    } else {
      _postError(response.data.MessageCode, response.data.Message);
    }
  }).catch(error => {
    if (error.response) {
      _postError(error.status, error.statusText);
    } else {
      console.log('Error', error);
    }
  });

  const _postSuccess = data => {
    callBack(data.acf);
  }

  const _postError = (code, message) => {
    Modal.error({
      title: 'get Posts By Id error',
      content: `${code}, ${message}`,
      maskClosable: true,
      okText: '確定',
    });
  }
}

export const getPostsByCategorieId = (id, callBack) => {
  axios.get(`${parseUrl.v2ApiUrl}posts?categories=${id}&_embed&per_page=100`, w.previewId && w.previewId !== '' ? {
    headers: { 'X-WP-Nonce': w.previewId }
  } : {}).then(response => {
    if (response.status === 200) {
      _postSuccess(response.data);
    } else {
      _postError(response.data.MessageCode, response.data.Message);
    }
  }).catch(error => {
    if (error.response) {
      _postError(error.status, error.statusText);
    } else {
      console.log('Error', error);
    }
  });

  const _postSuccess = data => {
    callBack(data);
  }

  const _postError = (code, message) => {
    Modal.error({
      title: 'get Posts By CategorieId error',
      content: `${code}, ${message}`,
      maskClosable: true,
      okText: '確定',
    });
  }
}

export const getPagesData = callBack => {
  axios.get(`${parseUrl.v2ApiUrl}pages?_embed&per_page=100`, w.previewId && w.previewId !== '' ? {
    headers: { 'X-WP-Nonce': w.previewId }
  } : {}).then(response => {
    if (response.status === 200) {
      _postSuccess(response.data);
    } else {
      _postError(response.data.MessageCode, response.data.Message);
    }
  }).catch(error => {
    if (error.response) {
      _postError(error.status, error.statusText);
    } else {
      console.log('Error', error);
    }
  });

  const _postSuccess = data => {
    callBack(data);
  }

  const _postError = (code, message) => {
    Modal.error({
      title: 'get Pages data error',
      content: `${code}, ${message}`,
      maskClosable: true,
      okText: '確定',
    });
  }
}

export const getPageDataById = (id, callBack) => {
    axios.get(`${parseUrl.v2ApiUrl}pages/${id}?_embed`, w.previewId && w.previewId !== '' ? {
      headers: { 'X-WP-Nonce': w.previewId }
    } : {}).then(response => {
      if (response.status === 200) {
        _postSuccess(response.data);
      } else {
        _postError(response.data.MessageCode, response.data.Message);
      }
    }).catch(error => {
      if (error.response) {
        _postError(error.status, error.statusText);
      } else {
        console.log('Error', error);
      }
    });

  const _postSuccess = data => {
    callBack(data);
  }

  const _postError = (code, message) => {
    Modal.error({
      title: 'get Pages data error',
      content: `${code}, ${message}`,
      maskClosable: true,
      okText: '確定',
    });
  }
}

export const getCategoriesData = callBack => {
  axios.get(`${parseUrl.v2ApiUrl}categories`, w.previewId && w.previewId !== '' ? {
    headers: { 'X-WP-Nonce': w.previewId }
  } : {}).then(response => {
    if (response.status === 200) {
      _postSuccess(response.data);
    } else {
      _postError(response.data.MessageCode, response.data.Message);
    }
  }).catch(error => {
    if (error.response) {
      _postError(error.status, error.statusText);
    } else {
      console.log('Error', error);
    }
  });

  const _postSuccess = data => {
    callBack(data);
  }

  const _postError = (code, message) => {
    Modal.error({
      title: 'get Categories data error',
      content: `${code}, ${message}`,
      maskClosable: true,
      okText: '確定',
    });
  }
}

export const getMenuByName = (name, callBack) => {
  axios.get(`${parseUrl.menuApiUrl}${name}`, w.previewId && w.previewId !== '' ? {
    headers: { 'X-WP-Nonce': w.previewId }
  } : {}).then(response => {
    if (response.status === 200) {
      _postSuccess(response.data);
    } else {
      _postError(response.data.MessageCode, response.data.Message);
    }
  }).catch(error => {
    if (error.response) {
      _postError(error.status, error.statusText);
    } else {
      console.log('Error', error);
    }
  });

  const _postSuccess = data => {
    callBack(data);
  }

  const _postError = (code, message) => {
    Modal.error({
      title: 'get Menu By Name error',
      content: `${code}, ${message}`,
      maskClosable: true,
      okText: '確定',
    });
  }
}

export const getPostsByTagId = (id, callBack) => {
  axios.get(`${parseUrl.v2ApiUrl}posts?tags=${id}&_embed&per_page=100`, w.previewId && w.previewId !== '' ? {
    headers: { 'X-WP-Nonce': w.previewId }
  } : {}).then(response => {
    if (response.status === 200) {
      _postSuccess(response.data);
    } else {
      _postError(response.data.MessageCode, response.data.Message);
    }
  }).catch(error => {
    if (error.response) {
      _postError(error.status, error.statusText);
    } else {
      console.log('Error', error);
    }
  });

  const _postSuccess = data => {
    callBack(data);
  }

  const _postError = (code, message) => {
    Modal.error({
      title: 'get Posts By tagId error',
      content: `${code}, ${message}`,
      maskClosable: true,
      okText: '確定',
    });
  }
}

export const getTagsData = (callBack) => {
  axios.get(`${parseUrl.v2ApiUrl}tags?per_page=100`, w.previewId && w.previewId !== '' ? {
    headers: { 'X-WP-Nonce': w.previewId }
  } : {}).then(response => {
    if (response.status === 200) {
      _postSuccess(response.data);
    } else {
      _postError(response.data.MessageCode, response.data.Message);
    }
  }).catch(error => {
    if (error.response) {
      _postError(error.status, error.statusText);
    } else {
      console.log('Error', error);
    }
  });

  const _postSuccess = data => {
    callBack(data);
  }

  const _postError = (code, message) => {
    Modal.error({
      title: 'get Tags Data error',
      content: `${code}, ${message}`,
      maskClosable: true,
      okText: '確定',
    });
  }
}

export const getUserById = (id, callBack) => {
  axios.get(`${parseUrl.v2ApiUrl}users/${id}`, w.previewId && w.previewId !== '' ? {
    headers: { 'X-WP-Nonce': w.previewId }
  } : {}).then(response => {
    if (response.status === 200) {
      _postSuccess(response.data);
    } else {
      _postError(response.data.MessageCode, response.data.Message);
    }
  }).catch(error => {
    if (error.response) {
      _postError(error.status, error.statusText);
    } else {
      console.log('Error', error);
    }
  });

  const _postSuccess = data => {
    callBack(data);
  }

  const _postError = (code, message) => {
    Modal.error({
      title: 'get User By Id error',
      content: `${code}, ${message}`,
      maskClosable: true,
      okText: '確定',
    });
  }
}

export const getApi = (search, callBack) => {
  axios.get(`${parseUrl.v2ApiUrl}${search}`, w.previewId && w.previewId !== '' ? {
    headers: { 'X-WP-Nonce': w.previewId }
  } : {}).then(response => {
    if (response.status === 200) {
      _postSuccess(response.data);
    } else {
      _postError(response.data.MessageCode, response.data.Message);
    }
  }).catch(error => {
    if (error.response) {
      _postError(error.status, error.statusText);
    } else {
      console.log('Error', error);
    }
  });

  const _postSuccess = data => {
    callBack(data);
  }

  const _postError = (code, message) => {
    Modal.error({
      title: 'get api error',
      content: `${code}, ${message}`,
      maskClosable: true,
      okText: '確定',
    });
  }
}

export const getPostDataByType = (type, callBack) => {
  axios.get(`${parseUrl.v2ApiUrl}${type}?_embed&per_page=100`, w.previewId && w.previewId !== '' ? {
    headers: { 'X-WP-Nonce': w.previewId }
  } : {}).then(response => {
    if (response.status === 200) {
      _postSuccess(response.data);
    } else {
      _postError(response.data.MessageCode, response.data.Message);
    }
  }).catch(error => {
    if (error.response) {
      _postError(error.status, error.statusText);
    } else {
      console.log('Error', error);
    }
  });

  const _postSuccess = data => {
    callBack(data);
  }

  const _postError = (code, message) => {
    Modal.error({
      title: 'get PostData By type error',
      content: `${code}, ${message}`,
      maskClosable: true,
      okText: '確定',
    });
  }
}

export const fatchPagePostData = (url, filter) => {
  const _postError = (code, message) => {
    Modal.error({
      title: `fatch data error`,
      content: `${code}, ${message}, ${url}${filter}`,
      maskClosable: true,
      okText: '確定',
    });
  }

  return axios.get(`${parseUrl.apiUrl}${url}${filter}`, {
    headers: w.previewId && w.previewId !== '' ? { 'X-WP-Nonce': w.previewId, 'Accept': 'application/json' } : { 'Accept': 'application/json' }
  }).then(response => {
    if (response.status === 200) {
      return response;
    } else {
      _postError(response.data.MessageCode, response.data.Message);
    }
  }).catch(error => {
    if (error.response) {
      _postError(error.status, error.statusText);
    } else {
      console.log('Error', error);
    }
  });
}

export const fatchAllPost = (url, filter, pageNum) => {
  let allFatch = [];

  for (let index = 1; index < pageNum; index++) {
    allFatch.push(fatchPagePostData(url, `${filter}${index + 1}`));
  }

  return axios.all(allFatch);
}

export const getAllPost = async (url, filter, callBack) => {
  const response = await fatchPagePostData(url, `${filter}1`);
  const pageNum = parseInt(response.headers['x-wp-totalpages']);

  if (pageNum > 1) {
    const allResponse = await fatchAllPost(url, filter, pageNum);

    callBack(allResponse.reduce((total, current) => { return [...total, ...current.data] }, [...response.data]))
  } else {
    callBack(response.data)
  }
}