const _w = window;

export const animateToTop = () => {
  const scrollToTop = _w.setInterval(function () {
    const pos = _w.pageYOffset;
    if (pos > 0) {
      _w.scrollTo(0, pos - 20);
    } else {
      _w.clearInterval(scrollToTop);
    }
  }, 5);
}

export const scrollToXY = (x, y) => {
  _w.scrollTo(x, y);
}

export const animateToPosition = y => {
  if(_w.pageYOffset > y){
    const scrollToPosition = _w.setInterval(() => {
      const pos = _w.pageYOffset;
      if (pos > y) {
        scrollToXY(0, pos - 20);
      } else {
        _w.clearInterval(scrollToPosition);
      }
    }, 12);
  } else if (_w.pageYOffset < y) {
    const scrollToPosition = _w.setInterval(() => {
      const pos = _w.pageYOffset;
      if (pos < y) {
        scrollToXY(0, pos + 20);
      } else {
        _w.clearInterval(scrollToPosition);
      }
    }, 12);
  }
}

export const stopWindowScroll = (e, id) => {
  const ele = document.getElementById(id).getElementsByTagName('div')[0];

  if (e.nativeEvent.deltaY <= 0) {// scrolling up
    if (ele.scrollTop <= 0) { e.preventDefault(); }
  } else {// scrolling down
    if (ele.scrollTop + ele.clientHeight >= ele.scrollHeight) {
      e.preventDefault();
    }
  }
}