import React, { Component } from 'react';
import Slider from 'react-slick';

import { scrollto } from '../../Service';
import HeroLogo from './hero.svg';
import './Hero.scss';

const carouselSetting = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: true,
  speed: 2000,
  dots: true,
  arrows: false,
  fade: true,
};

class Hero extends Component {
  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     distance: 0,
  //   }
  // }

  // componentDidMount() {
  //   window.addEventListener('scroll', () => {
  //     if (this.state.distance !== window.scrollY * 0.1 && window.scrollY < 700) {
  //       window.requestAnimationFrame(() => this.setState({ distance: window.scrollY * 0.1 }));
  //     }
  //   })
  // }

  _getHeroData = data => {
    let _arr = [];

    [1, 2, 3, 4, 5].forEach(num => {
      const _currentInfo = data[`home_hero_copy_${num}`];
      const _currentImg = data[`home_hero_img_${num}`];
      const _currentLink = data[`home_hero_link_${num}`];

      if (_currentInfo && _currentInfo !== '' && _currentImg && _currentImg !== '' && _currentLink && _currentLink !== '') {
        _arr.push({
          info: _currentInfo,
          img: _currentImg,
          link: _currentLink,
        })
      }
    });

    return _arr;
  }

  render() {
    const { className, _field } = this.props;

    return (
      <div className={`hero-wrap${className ? className : ''}`}>
        <div className="hero-abridge">KJMU</div>

        {
          _field ? (
            <Slider className="hero-slick" {...carouselSetting}>
              {
                this._getHeroData(_field).map((field, index) => {
                  return (
                    <div className="hero-photoWrap" key={`homeHero${field.link}${index}`}>
                      <div className="layout-container">
                        <div className="hero-infoWrap">
                          <p className="hero-info" dangerouslySetInnerHTML={{ __html: field.info }} />
                          <a className="hero-more link_white" href={field.link}>READ MORE</a>
                        </div>
                      </div>

                      <div className="hero-photo hero-photo_logo" style={{ backgroundImage: `url(${field.img})` }}>
                      </div>
                    </div>
                  )
                })
              }
            </Slider>
          ) : null
        }

        <img className="hero-logo" src={HeroLogo} alt="KJMU" />
        <a className="hero-scroll link_white" href="javascript:;" onClick={() => scrollto.animateToPosition(window.innerHeight)}>scroll</a>
      </div>
    );
  }
}

export default Hero;
