import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Select } from 'antd';

import { setGlobalUIStateByName } from '../../Actions';
import { wpApi } from '../../Service';
import LoadMore from '../../images/Icon/load_more.svg';
import Layout from '../../Containers/Layout/Layout';
import './Event.scss';

const Option = Select.Option;
const _w = window;
const _initPostNum = _w.innerWidth > 991 ? 6 : 3;

class Event extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentEvent: null,
      expiredData: null,
      showPostNum: _initPostNum,
      selectedYear: undefined,
    }
  }

  componentWillMount() {
    wpApi.getAllPost('wp/v2/event', '?_embed&per_page=100&page=', data => {
      this.setState({// 過濾當前活動
        currentEvent: data.filter(post => new Date() - new Date(post.acf.event_date_end) <= 0),
        expiredData: data.filter(post => new Date() - new Date(post.acf.event_date_end) > 0),
      })
    });
  }

  _getLastPost = data => {
    let _lastPost = [];
    const _softData = data.sort((a, b) => new Date(b.acf.event_date_end) - new Date(a.acf.event_date_end));

    _softData.forEach((event, index) => {
      _lastPost.push(
        <div className={`event-last${index === 0 || _softData.length % 2 === 0 ? ' event-last_wider' : ''}`} key={`lastEvent${_softData[index].date}`}>
          {
            _softData[index]._embedded['wp:featuredmedia'] ? (
              <a className="link_default content-photo" href={_softData[index].link} style={{ backgroundImage: `url(${_softData[index]._embedded['wp:featuredmedia'][0].source_url})` }} >
                <h3 className="content-heroTitle" dangerouslySetInnerHTML={{ __html: _softData[index].title.rendered }} />
              </a>
            ) : (
                <a className="link_default content-photo" href={_softData[index].link} style={{ backgroundColor: '#efefef' }} >
                  <h3 className="content-heroTitle" dangerouslySetInnerHTML={{ __html: _softData[index].title.rendered }} />
                </a>
              )
          }

          <div className="event-info">
            <div className="content-preview content-preview_mid" dangerouslySetInnerHTML={{ __html: _softData[index].excerpt.rendered }} />

            <a className="content-toknow" href={_softData[index].link}>/ 我要暸解 /</a>
          </div>
        </div>
      );
    });

    return _lastPost;
  }

  _showTagPost = (num, year) => {
    let _tagPost = [];
    const _postArr = year ? this.state.expiredData.filter(post => post.acf.event_date_start.includes(year)) : this.state.expiredData;

    if (_postArr) {
      for (let index = 0; index < num; index++) {
        if (_postArr[index]) {
          _tagPost.push(
            <div className="content-col content-col_event" key={`seeMore${_postArr[index].date}`}>
              <a className="link_default" href={_postArr[index].link}>
                {
                  _postArr[index]._embedded['wp:featuredmedia'] ? <div className="content-photo" style={{ backgroundImage: `url(${_postArr[index]._embedded['wp:featuredmedia'][0].source_url})` }} /> : <div className="content-photo" style={{ backgroundColor: '#efefef' }} />
                }
              </a>

              <div className="content-info">
                <a className="link_default" href={_postArr[index].link}><h3 className="content-colTitle content-colTitle_full" dangerouslySetInnerHTML={{ __html: _postArr[index].title.rendered }} /></a>

                <div className="content-preview content-preview_min" dangerouslySetInnerHTML={{ __html: _postArr[index].excerpt.rendered }} />
              </div>
            </div>
          )
        }
      }
    }

    return _tagPost;
  }

  _getYearArr = data => {
    let _yearArr = [];

    if (data && data.length > 1) {
      for (let index = 0; index < data.length; index++) {
        const _currentYear = new Date(data[index].acf.event_date_start).getFullYear();

        if (!_yearArr.includes(_currentYear)) {
          _yearArr.push(_currentYear)
        }
      }
    }

    return _yearArr;
  }

  render() {
    return (
      <Layout className="content-wrap" _temp="event">
        <div className="layout-container">
          <div className="content-pageWrap">
            <div className="content-pathWrap content-pathWrap_float">
              <a className="content-pathLink" href="javascript:;">近期活動</a>
            </div>
            {
              this.state.currentEvent ? <h1 className="content-title content-title_left">近期活動</h1> : null
            }

            {
              this.state.currentEvent ? (
                <div className="event-lastWrap">
                  {
                    this._getLastPost(this.state.currentEvent)
                  }
                </div>
              ) : null
            }

            {
              this.state.expiredData ? (
                <div className="event-loadMore">
                  {
                    this._getYearArr(this.state.expiredData).length > 1 ? (
                      <h1 className="content-title content-title_left">
                        過往活動<br className="event-selectBr" />
                        <Select className="event-select" placeholder="請選擇年份" onChange={val => this.setState({ selectedYear: val })}>
                          {
                            this._getYearArr(this.state.expiredData).map(year => {
                              return <Option value={year} key={`yearSelect${year}`}>{year}</Option>
                            })
                          }
                        </Select>
                      </h1>
                    ) : <h1 className="content-title content-title_left">過往活動</h1>
                  }

                  <div className="content-eventWrap">
                    {
                      this._showTagPost(this.state.showPostNum, this.state.selectedYear)
                    }
                  </div>

                  {
                    this.state.showPostNum < this.state.expiredData.length ? (
                      <a className="link_loadMore" href="javascript:;" onClick={() => this.setState({ showPostNum: this.state.showPostNum + (_w.innerWidth > 991 ? 6 : 3) })}>
                        <img src={LoadMore} alt="LoadMore" />
                      </a>
                    ) : null
                  }
                </div>
              ) : null
            }
          </div>
        </div>
      </Layout>
    );
  }
}

// 準備綁定用的mapStateToProps方法，
// 將store中的items屬性綁到這個元件的props.items屬性上
const mapStateToProps = store => ({
  showMenu: store.default.showMenu,
});

// 準備綁定用的DispatchToProps方法，
// 只需要onFecthLoadItems這個方法
const mapDispatchToProps = dispatch => (
  bindActionCreators({ setGlobalUIStateByName }, dispatch)
);

// 連接Redux store
export default connect(mapStateToProps, mapDispatchToProps)(Event);
