import Cookies from 'universal-cookie';

const cookies = new Cookies();
const _w = window;

export const protocol = _w.location.protocol;
export const origin = _w.location.origin;
export const pathname = _w.location.pathname;
export const hostName = _w.location.hostname.split('-');
// dev-retail.gogoro.com return ['dev', 'retail.gogoro.com'] 
export const serverEnv = () => {
  if (hostName.length > 0) {
    return hostName[0];
  }
}
export const isLocalEnv = () => {
  return _w.location.hostname.includes('localhost');
}
// ?id=123 return [{id: '123'}] 
export const queryUrlArr = url => {
  if (!url) url = _w.location.href;
  let a = document.createElement('a');
  a.href = url;
  return (function () {
    let ret = {};
    const seg = a.search.replace(/^\?/, '').split('&');
    let s;
    for (let i = 0; i < seg.length; i++) {
      if (!seg[i]) {
        continue;
      }
      s = seg[i].split('=');
      ret[s[0]] = s[1];
    }
    return ret;
  })();
}
// ?id=123 return '123'
export const queryByName = name => {
  var match = RegExp('[?&]' + name + '=([^&]*)').exec(_w.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}
// url/abc/1234 return ['url', 'abc', '1234']
export const getParameterArr = url => {
  const currentPath = url ? url : _w.location.pathname;
  return currentPath.replace(/^([^/])/, '/$1').split('/');
}

export const apiUrl = isLocalEnv() ? '//kjmu.org.tw/wp-json/' : `//${_w.location.host}/wp-json/`;
export const v2ApiUrl = isLocalEnv() ? '//kjmu.org.tw/wp-json/wp/v2/' : `//${_w.location.host}/wp-json/wp/v2/`;
export const fieldApiUrl = isLocalEnv() ? '//kjmu.org.tw/wp-json/acf/v3/' : `//${_w.location.host}/wp-json/acf/v3/`;
export const menuApiUrl = isLocalEnv() ? '//kjmu.org.tw/wp-json/wp-api-menus/v2/menu-locations/' : `//${_w.location.host}/wp-json/wp-api-menus/v2/menu-locations/`;

export const hrefToLogin = url => {
  const hrefUrl = url ? url : _w.location.href;
  cookies.set('ReferrerUrl', hrefUrl, { path: '/', domain: 'gogoro.com' });
  _w.location.href = 'account/login';
}
