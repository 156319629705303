import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Pagination } from 'antd';

import { setGlobalUIStateByName } from '../../Actions';
import { service } from '../../Service';
import Layout from '../../Containers/Layout/Layout';
import './Search.scss';

const _searchData = window.searchData;
const _pageSize = 10;

class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchData: [],
      pageNum: 1,
      articleNum: _searchData ? _searchData.length : 0,
    }
  }

  _getCurrentPaginationPost = num => {
    let _currentPost = [..._searchData];
    
    _currentPost = _currentPost.slice((num - 1) * _pageSize, (num - 1) * _pageSize + _pageSize);
    
    _currentPost = _currentPost.map(post => {
      post.excerpt = post.excerpt.replace('[&hellip;]', '...');
      
      return post;
    })

    setTimeout(() => {
      const gBox = document.getElementsByClassName('fadeIn');

      for (const box of gBox) {
        box.classList.add('animated');
      }
    }, 0);

    return _currentPost;
  }

  render() {
    return (
      <Layout className="content-wrap" _temp="search">
        <div className="layout-container">
          <div className="content-pageWrap">
            <div className="content-pathWrap content-pathWrap_float">
              <a className="content-pathLink" href="javascript:;">搜尋結果</a>
            </div>

            <header className="search-header">
              <h1 className="content-pageTitle">搜尋結果</h1>
              {
                this.state.articleNum === 0 ? <p className="search-info">什麼都沒找到</p> : (
                  <p className="search-info">
                    “{window.searchQuery}”
                    <span className="search-infoNum">共 {this.state.articleNum} 筆</span>
                  </p>
                )
              }
            </header>

            <div className="content-area">
              {
                _searchData && _searchData.length > 0 ? (
                  this._getCurrentPaginationPost(this.state.pageNum).map((post, index) => {
                    return (
                      <article className="search-box fadeIn" style={{ animationDelay: `${0.4 * index}s` }} key={`searchPost${post.permalink}`}>

                        <a className="link_default search-thumbnail" href={post.permalink}>
                          {
                            post.thumbnail ? <div className="search-photo" style={{ backgroundImage: `url(${post.thumbnail})` }} /> : <div className="search-photo" style={{ backgroundColor: '#efefef' }} />
                          }
                        </a>

                        <div className="search-boxInfo">
                          <h4 className="search-boxTitle">
                            <a className="link_default" href={post.permalink} dangerouslySetInnerHTML={{ __html: post.title }} />
                          </h4>

                          <div className="search-summary" dangerouslySetInnerHTML={{ __html: post.excerpt }} />

                          <p className="search-time">{service.getTimeByString(post.time, '.')}</p>
                        </div>

                      </article>
                    )
                  })
                ) : (
                    <div>
                      <a className="btn" href="javascript:;" onClick={() => this.props.setGlobalUIStateByName('showMenu', 'search')}>重新搜尋</a>
                    </div>
                  )
              }

              <Pagination current={this.state.pageNum} total={this.state.articleNum} defaultPageSize={_pageSize} hideOnSinglePage={true} onChange={val => this.setState({ pageNum: val })} />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

// 準備綁定用的mapStateToProps方法，
// 將store中的items屬性綁到這個元件的props.items屬性上
const mapStateToProps = store => ({
  showMenu: store.default.showMenu,
});

// 準備綁定用的DispatchToProps方法，
// 只需要onFecthLoadItems這個方法
const mapDispatchToProps = dispatch => (
  bindActionCreators({ setGlobalUIStateByName }, dispatch)
);

// 連接Redux store
export default connect(mapStateToProps, mapDispatchToProps)(Search);
