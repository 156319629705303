import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { setGlobalUIStateByName } from '../../Actions';
import { wpApi, scrollto } from '../../Service';
import Header from '../../Containers/Header/Header';
import Footer from '../../Containers/Footer/Footer';
import '../../Vendor/style/layout.scss';

const _w = window;

class Layout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      temp: '',
      mainMenu: [],
      quickMenu: [],
      goTop: false,
    }
  }

  componentWillMount() {
    console.log(this.props._temp);
    this._getMenuDataByName('mainMenu');
    this._getMenuDataByName('quickMenu');
    _w.addEventListener('scroll', this._handleScroll);
  }

  componentDidMount() {
    setTimeout(() => {
      (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = 'https://connect.facebook.net/zh_TW/sdk.js#xfbml=1&version=v3.2';
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    }, 1000);
  }

  _getMenuDataByName = name => {
    wpApi.getMenuByName(name, data => this.setState({ [`${name}`]: data }));
  }

  _toggleMenu = name => {
    const { setGlobalUIStateByName } = this.props;
    name === 'close' ? setGlobalUIStateByName('showMenu', null) : setGlobalUIStateByName('showMenu', name);
  }

  _handleScroll = () => {
    const _scrollY = _w.scrollY;
    const _innerHeight = _w.innerHeight;

    if (_scrollY > (_innerHeight * 2) && this.state.goTop === false) {
      this.setState({ goTop: true });
    } else if (_scrollY < (_innerHeight * 2) && this.state.goTop === true) {
      this.setState({ goTop: false });
    }
  }

  render() {
    const { className, _temp, children, showMenu } = this.props;

    return (
      <div className={className}>
        <div id="fb-root"></div>

        <Header _temp={_temp} _showMenu={showMenu} _toggleMenu={this._toggleMenu} _mainMenu={this.state.mainMenu} _quickMenu={this.state.quickMenu} />

        {children}

        <Footer _toggleMenu={this._toggleMenu} />

        <a className={`content-goTop${this.state.goTop ? ' active' : ''}`} href="javascript:;" onClick={() => scrollto.animateToTop()}>
          <svg x="0px" y="0px" width="512px" height="512px" viewBox="0 0 284.929 284.929" enableBackground="new 0 0 284.929 284.929">
            <g>
              <path d="M282.082,195.285L149.028,62.24c-1.901-1.903-4.088-2.856-6.562-2.856s-4.665,0.953-6.567,2.856L2.856,195.285   C0.95,197.191,0,199.378,0,201.853c0,2.474,0.953,4.664,2.856,6.566l14.272,14.271c1.903,1.903,4.093,2.854,6.567,2.854   c2.474,0,4.664-0.951,6.567-2.854l112.204-112.202l112.208,112.209c1.902,1.903,4.093,2.848,6.563,2.848   c2.478,0,4.668-0.951,6.57-2.848l14.274-14.277c1.902-1.902,2.847-4.093,2.847-6.566   C284.929,199.378,283.984,197.188,282.082,195.285z" fill="#FFFFFF" />
            </g>
          </svg>
        </a>
      </div>
    )
  }
}

// 準備綁定用的mapStateToProps方法，
// 將store中的items屬性綁到這個元件的props.items屬性上
const mapStateToProps = store => ({
  showMenu: store.default.showMenu,
});

// 準備綁定用的DispatchToProps方法，
// 只需要onFecthLoadItems這個方法
const mapDispatchToProps = dispatch => (
  bindActionCreators({ setGlobalUIStateByName }, dispatch)
);

// 連接Redux store
export default connect(mapStateToProps, mapDispatchToProps)(Layout);