import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Slider from 'react-slick';

import { setGlobalUIStateByName } from '../../Actions';
import { wpApi, service } from '../../Service';
import Layout from '../../Containers/Layout/Layout';
import './About.scss';

class About extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageAcf: null,
      pageData: null,
      currentTab: '',
      reportPosts: null,
    }
  }

  componentWillMount() {
    wpApi.getPageDataById(window.aboutId, data => {
      this.setState({
        pageAcf: data.acf,
        pageData: data,
        currentTab: 1,
        animateTab: '',
        animateTabContent: '',
      })
    });

    this._getLatestReport('媒體報導');
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ animateTab: ' fadeIn animated', animateTabContent: ' fadeIn animated' });
    }, 1500);
  }

  _getLatestReport = name => {
    const _filterPostIdBySlug = data => {
      const _tagId = data.filter(obj => { return obj.name === name })[0].id;

      wpApi.getPostsByTagId(_tagId, data => this.setState({
        reportPosts: data.sort((a, b) => { return new Date(b.date) - new Date(a.date) })
      }));
    }

    wpApi.getTagsData(_filterPostIdBySlug);
  }

  render() {
    const carouselSetting = {
      infinite: true,
      slidesToShow: !this.state.reportPosts || this.state.reportPosts.length > 3 ? 3 : this.state.reportPosts.length,
      slidesToScroll: 1,
      speed: 1000,
      dots: true,
      arrows: false,
      responsive: [
        { breakpoint: 540, settings: { slidesToShow: 1 } },
        { breakpoint: 768, settings: { slidesToShow: 2 } },
      ]
    };

    return (
      <Layout className="content-wrap" _temp="about">
        {
          this.state.pageData ? (
            <div className="layout-container">
              <div className="content-hero">
                <div className="content-pathWrap content-pathWrap_float">
                  {/* <a className="content-pathLink" href="javascript:;" dangerouslySetInnerHTML={{ __html: this.state.pageData.title.rendered }} /> */}

                  <a className="content-pathLink" href="javascript:;">關於我們</a>

                  <a className="content-pathLink" href="javascript:;">
                    {this.state.pageAcf[`about_context_title_${this.state.currentTab}`]}
                  </a>
                </div>

                <div className="about-wrap">
                  <div className="tab-ctlWrap">
                    {
                      [1, 2, 3, 4, 5].map(index => {
                        return <a className={`tab-link${this.state.currentTab === index ? ' active' : ''}`} href="javascript:;" key={`tabLink${index}`} onClick={() => this.setState({ currentTab: index, animateTab: ' fadeIn animated', animateTabContent: ' fadeIn animated' })}>{`${this.state.pageAcf[`about_context_title_${index}`]}`}</a>
                      })
                    }
                  </div>

                  <div className={`tab-wrap${this.state.animateTab}`} onAnimationEnd={() => this.setState({ animateTab: '' })} style={{ animationDelay: `.5s` }}>
                    {
                      this.state.pageAcf[`about_context_img_${this.state.currentTab}`] ? (
                        <div className="about-hero content-heroPhoto" style={{ backgroundImage: `url(${this.state.pageAcf[`about_context_img_${this.state.currentTab}`]})` }}>
                          <h2 className="about-heroTitle">
                            {this.state.pageAcf[`about_context_title_${this.state.currentTab}`]}
                          </h2>
                        </div>
                      ) : null
                    }
                    <div className={`tab-content${this.state.animateTab}`} onAnimationEnd={() => this.setState({ animateTab: '' })} style={{ animationDelay: `1s` }}>
                      {
                        this.state.pageAcf[`about_context_${this.state.currentTab}`] ? <div className="about-content" dangerouslySetInnerHTML={{ __html: this.state.pageAcf[`about_context_${this.state.currentTab}`] }} /> : null
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null
        }

        {
          this.state.reportPosts && this.state.currentTab === 5 ? (
            <div className="content-rowBg content-rowBg_event">
              <div className="layout-container">
                <div className="content-row content-row_about">
                  <h1 className="content-title">媒體報導</h1>

                  <Slider className="about-slider" {...carouselSetting}>
                    {
                      this.state.reportPosts.map(post => {
                        return (
                          <div className="content-col content-col_about" key={`reportPosts${post.date}`}>
                            {
                              post._embedded['wp:featuredmedia'] ? <div className="content-photo" style={{ backgroundImage: `url(${post._embedded['wp:featuredmedia'][0].source_url})` }} /> : <div className="content-photo" style={{ backgroundColor: '#efefef' }} />
                            }

                            <div className="content-info">
                              <p className="content-time">{service.getTimeByString(post.date, '.')}</p>

                              <a className="link_default" href={post.link}><h3 className="content-colTitle" dangerouslySetInnerHTML={{ __html: post.title.rendered }} /></a>

                              <div className="content-preview content-preview_min" dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
                            </div>
                          </div>
                        )
                      })
                    }
                  </Slider>
                </div>
              </div>
            </div>
          ) : null
        }
        {
          this.state.currentTab === 5 ? (
            <div className="content-rowBg">
              <div className="layout-container">
                <div className="content-row">
                  <div className="about-info">
                    <p dangerouslySetInnerHTML={{ __html: this.state.pageAcf.about_support_copy }} />

                    <a className="content-toknow" href="/support/">/ 支持基金會 /</a>
                  </div>
                </div>
              </div>
            </div>
          ) : null
        }
      </Layout>
    );
  }
}

// 準備綁定用的mapStateToProps方法，
// 將store中的items屬性綁到這個元件的props.items屬性上
const mapStateToProps = store => ({
  showMenu: store.default.showMenu,
});

// 準備綁定用的DispatchToProps方法，
// 只需要onFecthLoadItems這個方法
const mapDispatchToProps = dispatch => (
  bindActionCreators({ setGlobalUIStateByName }, dispatch)
);

// 連接Redux store
export default connect(mapStateToProps, mapDispatchToProps)(About);
