import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Pagination } from 'antd';

import { setGlobalUIStateByName } from '../../Actions';
import { wpApi, service } from '../../Service';
import Layout from '../../Containers/Layout/Layout';
import './News.scss';

const _pageSize = 10;
const _w = window;

class News extends Component {
  constructor(props) {
    super(props);

    this.state = {
      postType: 'news',
      newsPost: [],
      pageNum: 1,
      articleNum: 0,
    }
  }

  componentWillMount() {
    if (_w.tagId) {
      this.setState({
        postType: 'tag'
      }, () => this._getAllTagsPost(_w.tagId));
    } else if (_w.authorId) {
      this.setState({
        postType: 'author'
      }, () => this._getAllAuthorPost(_w.authorId));
    } else {
      this._getAllNewsPost('news');
    }
  }

  _getAllTagsPost = id => {
    wpApi.getApi(`posts?tags=${id}&_embed`, data => {
      this.setState({
        newsPost: [...data].sort((a, b) => { return new Date(b.date) - new Date(a.date) }),
        articleNum: [...data].length
      })
    });

    wpApi.getApi(`event?tags=${id}&_embed`, data => {
      this.setState({
        newsPost: [...this.state.newsPost, ...data].sort((a, b) => { return new Date(b.date) - new Date(a.date) }),
        articleNum: [...this.state.newsPost, ...data].length
      })
    });

    wpApi.getApi(`gallery?tags=${id}&_embed`, data => {
      this.setState({
        newsPost: [...this.state.newsPost, ...data].sort((a, b) => { return new Date(b.date) - new Date(a.date) }),
        articleNum: [...this.state.newsPost, ...data].length
      })
    });

    wpApi.getApi(`project?tags=${id}&_embed`, data => {
      this.setState({
        newsPost: [...this.state.newsPost, ...data].sort((a, b) => { return new Date(b.date) - new Date(a.date) }),
        articleNum: [...this.state.newsPost, ...data].length
      })
    });
  }

  _getAllAuthorPost = id => {
    wpApi.getApi(`posts?author=${id}&_embed`, data => {
      this.setState({
        newsPost: [...data].sort((a, b) => { return new Date(b.date) - new Date(a.date) }),
        articleNum: [...data].length
      })
    });
  }

  _getAllNewsPost = categorie => {
    const _filterPostIdByCategorie = data => {
      const _categorieId = data.filter(obj => { return obj.slug === categorie })[0].id;

      wpApi.getAllPost(`wp/v2/posts`, `?categories=${_categorieId}&_embed&per_page=100&page=`, data => this.setState({
        newsPost: data.sort((a, b) => { return new Date(b.date) - new Date(a.date) }),
        articleNum: data.length
      }));
    }

    wpApi.getCategoriesData(_filterPostIdByCategorie);
  }

  _getCurrentPaginationPost = num => {
    let _currentPost = [...this.state.newsPost];

    _currentPost = _currentPost.slice((num - 1) * _pageSize, (num - 1) * _pageSize + _pageSize);

    setTimeout(() => {
      const gBox = document.getElementsByClassName('fadeIn');

      for (const box of gBox) {
        box.classList.add('animated');
      }
    }, 0);

    return _currentPost;
  }

  render() {
    return (
      <Layout className="content-wrap news-wrap" _temp="news">
        <div className="layout-container">
          <div className="content-pageWrap">
            <div className="content-pathWrap content-pathWrap_float">
              {
                this.state.postType === 'news' ? <a className="content-pathLink" href="/最新消息/">最新消息</a> : null
              }

              {
                this.state.postType === 'tag' ? <a className="content-pathLink" href="javascript:;">分類文章</a> : null
              }

              {
                this.state.postType === 'author' ? <a className="content-pathLink" href="javascript:;">作者文章</a> : null
              }
            </div>

            {
              this.state.postType === 'news' ? <h1 className="content-pageTitle">最新消息</h1> : null
            }

            {
              this.state.postType === 'tag' ? <h1 className="content-pageTitle">{_w.tagName}</h1> : null
            }

            {
              this.state.postType === 'author' ? <h1 className="content-pageTitle">{_w.authorName}</h1> : null
            }

            <div className="news-postWrap">
              {
                this.state.newsPost && this.state.newsPost.length > 0 ? (
                  this._getCurrentPaginationPost(this.state.pageNum).map((post, index) => {
                    return (
                      <article className="news-box fadeIn" style={{ animationDelay: `${0.4 * index}s` }} key={`newsPost${post.guid.rendered}`}>
                        <a className="link_default news-thumbnail" href={post.link}>
                          {
                            post._embedded['wp:featuredmedia'] ? <div className="news-photo" style={{ backgroundImage: `url(${post._embedded['wp:featuredmedia'][0].source_url})` }} /> : <div className="news-photo" style={{ backgroundColor: '#efefef' }} />
                          }
                        </a>

                        <div className="news-boxInfo">
                          <p className="news-time">{service.getTimeByString(post.date, '.')}</p>

                          <h4 className="news-boxTitle">
                            <a className="link_default" href={post.link} dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
                          </h4>

                          <div className="news-summary" dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
                        </div>
                      </article>
                    )
                  })
                ) : null
              }
            </div>

            <Pagination current={this.state.pageNum} total={this.state.articleNum} defaultPageSize={_pageSize} hideOnSinglePage={true} onChange={val => this.setState({ pageNum: val })} />
          </div>
        </div>
      </Layout>
    );
  }
}

// 準備綁定用的mapStateToProps方法，
// 將store中的items屬性綁到這個元件的props.items屬性上
const mapStateToProps = store => ({
  showMenu: store.default.showMenu,
});

// 準備綁定用的DispatchToProps方法，
// 只需要onFecthLoadItems這個方法
const mapDispatchToProps = dispatch => (
  bindActionCreators({ setGlobalUIStateByName }, dispatch)
);

// 連接Redux store
export default connect(mapStateToProps, mapDispatchToProps)(News);
