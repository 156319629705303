import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { setGlobalUIStateByName } from '../../Actions';
import { wpApi, service } from '../../Service';
import Layout from '../../Containers/Layout/Layout';
import ShareList from '../../Containers/ShareList/ShareList';
import LightBox from '../../Containers/LightBox/LightBox';
import './Single.scss';

class Single extends Component {
  constructor(props) {
    super(props);

    this.state = {
      singleData: null,
      projectPost: false,
      authorData: null,
      showDescription: false,
      tagsData: null,
      tagsPost: [],
    }
  }

  componentWillMount() {
    wpApi.getSinglePostDataById(window.postId, data => {
      this.setState({ singleData: data }, () => {
        // check single type
        this._isProjectPost(data);

        if (data.tags.length > 0) {
          this._getTagsData(data.tags);
        }
      })
    });
  }

  _isProjectPost = postData => {
    const _filterProjectId = data => {
      const _projectId = data.filter(obj => { return obj.slug === 'project' })[0].id;

      if (_projectId && postData.categories.some(id => { return id === _projectId })) {
        this._getAuthorData(postData.author);
        this.setState({ projectPost: true });
      }
    }

    wpApi.getCategoriesData(_filterProjectId);
  }

  _getAuthorData = id => {
    wpApi.getUserById(id, data => {
      this.setState({ authorData: data });
    });
  }

  _getTagsData = tags => {
    wpApi.getTagsData(data => {
      const _tagData = data.filter(tag => tags.some(id => id === tag.id));

      this.setState({ tagsData: _tagData }, () => {
        if (_tagData && _tagData.length > 0) {
          this._getPostForTag(_tagData);
        }
      });
    });
  }

  _getPostForTag = tagsData => {
    tagsData.forEach(tag => {
      wpApi.getPostsByTagId(tag.id, data => {
        let _postArr = [];

        data.forEach(post => {
          if (this.state.tagsPost.every(obj => { return post.id !== obj.id }) && post.id !== this.state.singleData.id) {
            _postArr.push(post);
          }
        })

        this.setState({ tagsPost: this.state.tagsPost.concat(_postArr) });
      })
    });
  }

  addOrderForm = () => {
    document.getElementById('mc_embed_signup').classList.add('j-order', 'active');
  }

  render() {
    return (
      <Layout className="content-wrap" _temp="single">
        {
          this.state.singleData ? (
            <div className="Single-wrap">
              {
                this.state.projectPost ? (
                  <div className="content-hero content-hero_full">
                    <div className="content-pathWrap content-pathWrap_float">
                      <a className="content-pathLink" href="javascript:;">專題企劃</a>
                      <a className="content-pathLink" href="javascript:;" dangerouslySetInnerHTML={{ __html: this.state.singleData.title.rendered }} />
                    </div>

                    <div className="content-heroPhoto content-heroPhoto_full" style={{ backgroundImage: `url(${this.state.singleData._embedded['wp:featuredmedia'][0].source_url})` }}>
                      <h1 className="content-heroTitle" dangerouslySetInnerHTML={{ __html: this.state.singleData.title.rendered }} />
                    </div>
                  </div>
                ) : (
                    <div className="layout-container">
                      <div className="content-hero">
                        <div className="content-pathWrap content-pathWrap_float">
                          <a className="content-pathLink" href="/最新消息/">最新消息</a>

                          <a className="content-pathLink" href="javascript:;" dangerouslySetInnerHTML={{ __html: this.state.singleData.title.rendered }} />
                        </div>

                        <h1 className="content-title content-title_left" dangerouslySetInnerHTML={{ __html: this.state.singleData.title.rendered }} />

                        {
                          this.state.singleData._embedded['wp:featuredmedia'] ? <div className="content-heroPhoto" style={{ backgroundImage: `url(${this.state.singleData._embedded['wp:featuredmedia'][0].source_url})` }}>
                          </div> : null
                        }
                      </div>
                    </div>
                  )
              }

              {
                this.state.projectPost ? (
                  <div className="layout-container">
                    <div className="Single-content Single-content_project">
                      <div className="Single-left">
                        {
                          this.state.authorData ? (
                            <div className="Single-leftBox">
                              <p className="Single-nameTitle">作者</p>

                              <h3 className="Single-name"><a className="link_red" href={this.state.authorData.link}>{this.state.authorData.name}</a></h3>

                              <p className={`Single-description${this.state.showDescription ? ' active' : ''}`}>{this.state.authorData.description}</p>

                              <a className="link_black Single-more" href="javascript:;" onClick={() => this.setState({ showDescription: !this.state.showDescription })}>MORE</a>
                            </div>
                          ) : null
                        }

                        <div className="Single-leftBox Single-leftBox_social">
                          <p className="content-shareTitle">分享</p>

                          <ShareList _title={this.state.singleData.title.rendered} />
                        </div>
                      </div>

                      <div id="printContent" className="Single-right">
                        {
                          this.state.tagsData ? (
                            <div className="content-tagWrap">
                              <p className="content-tagTitle">標籤</p>

                              <ul className="content-tagList">
                                {
                                  this.state.tagsData.map(obj => {
                                    return <li key={`tagList${obj.id}`}><a className="btn" href={obj.link}>{obj.name}</a></li>
                                  })
                                }
                              </ul>
                            </div>
                          ) : null
                        }

                        <div id="articleContentProject" className="Single-article" dangerouslySetInnerHTML={{ __html: this.state.singleData.content.rendered }} />

                        <div className="Single-leftBox Single-leftBox_article">
                          <p className="content-shareTitle">分享</p>

                          <ShareList _title={this.state.singleData.title.rendered} />
                        </div>

                        <div className="Single-infoWrap">
                          <p className="Single-info">2011～2013年空間母語基金會舉辦了二次建築論壇的總結會議和一次研討會，邀集包括日本、新加坡、香港和中國大陸等國內外近三十位建築名師與談，在建築的命題中，涵容人文、藝術、歷史、科學，以統整的角度，為台灣近二十年來的地域主義建築發展留下註腳。</p>

                          <div className="Single-btn">
                            <a className="btn" href="/關於我們/">關於我們</a>
                            <a className="btn" href="#" onClick={this.addOrderForm}>訂閱電子報</a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="layout-container">
                      <div className="Single-content">
                        <p className="content-tagTitle">或許你會想看</p>
                        {
                          this.state.tagsPost.length > 0 ? (
                            <div className="content-morePost">
                              {
                                service.getRamdonPost(this.state.tagsPost, 3).map(post => {
                                  return (
                                    <div className="content-col content-col_event" key={`tagsPost${post.id}`}>
                                      <a className="link_default" href={post.link}>
                                        <div className="content-photo" style={{ backgroundImage: `url(${post._embedded['wp:featuredmedia'] ? post._embedded['wp:featuredmedia'][0].source_url : '#efefef'})` }}></div>
                                      </a>

                                      <div className="content-info">
                                        <p className="content-time">{service.getTimeByString(post.date, '.')}</p>

                                        <a className="link_default" href={post.link}>
                                          <h3 className="content-colTitle content-colTitle_full" dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
                                        </a>
                                      </div>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          ) : null
                        }
                      </div>
                    </div>
                  </div>
                ) : (
                    <div>
                      <div id="printContent" className="layout-container">
                        <div className="content-article">
                          {
                            this.state.tagsData ? (
                              <div className="content-tagWrap">
                                <p className="content-tagTitle">標籤</p>

                                <ul className="content-tagList">
                                  {
                                    this.state.tagsData.map(obj => {
                                      return <li key={`tagList${obj.id}`}><a className="btn" href={obj.link}>{obj.name}</a></li>
                                    })
                                  }
                                </ul>
                              </div>
                            ) : null
                          }

                          <div id="articleContent" className="Single-article" dangerouslySetInnerHTML={{ __html: this.state.singleData.content.rendered }} />

                        </div>
                      </div>
                      <div className="content-socialWrap content-socialWrap_gray">
                        <p className="content-shareTitle">分享</p>

                        <ShareList />
                      </div>
                    </div>
                  )
              }

              <div className="layout-container">
                <div className="content-fbComments">
                  <div className="fb-comments" data-width="100%" data-href={this.state.singleData.link} data-numposts="5" />
                </div>
              </div>
            </div>
          ) : null
        }

        <LightBox _id={`articleContent${this.state.projectPost ? 'Project' : ''}`} />
      </Layout>
    );
  }
}

// 準備綁定用的mapStateToProps方法，
// 將store中的items屬性綁到這個元件的props.items屬性上
const mapStateToProps = store => ({
  showMenu: store.default.showMenu,
});

// 準備綁定用的DispatchToProps方法，
// 只需要onFecthLoadItems這個方法
const mapDispatchToProps = dispatch => (
  bindActionCreators({ setGlobalUIStateByName }, dispatch)
);

// 連接Redux store
export default connect(mapStateToProps, mapDispatchToProps)(Single);
