import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Pagination, Input, Icon, Select } from 'antd';

import { setGlobalUIStateByName } from '../../Actions';
import { wpApi } from '../../Service';
import Layout from '../../Containers/Layout/Layout';
import './Gallery.scss';

const _w = window;
const Option = Select.Option;
const _pageSize = 9;

class Gallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageData: null,
      googleCode: null,
      oriData: null,
      galleryData: null,
      pageNum: 1,
      articleNum: 0,
      searchKey: undefined,
      collection: undefined,
      decade: undefined,
      neighborhood: undefined,
      style: undefined,
      architect: undefined,
      viewType: 0,
    }
  }

  componentWillMount() {
    wpApi.getPageDataById(_w.galleryPageId, data => {
      const _el = document.createElement('textarea');
      _el.innerHTML = data.acf.google_map_code;
      const _decoded = _el.value;

      this.setState({ pageData: data, googleCode: _decoded });
    });

    wpApi.getAllPost('wp/v2/gallery', '?_embed&per_page=100&page=', data => {
      const _sortData = data.sort((a, b) => { return new Date(b.date) - new Date(a.date) });

      this.setState({
        oriData: _sortData,
        galleryData: _sortData,
        articleNum: data.length
      })
    });
  }

  _filterGalleryData = () => {
    let _filterPost = [...this.state.oriData]

    if(this.state.searchKey){
      _filterPost = _filterPost.filter(post => post.title.rendered.toUpperCase().includes(this.state.searchKey.toUpperCase()));
    }

    if(this.state.collection){
      _filterPost = _filterPost.filter(post => post.acf.gallery_collection.toUpperCase().includes(this.state.collection.toUpperCase()));
    }

    if(this.state.decade){
      _filterPost = _filterPost.filter(post => post.acf.gallery_decade.toUpperCase().includes(this.state.decade.toUpperCase()));
    }

    if(this.state.neighborhood){
      _filterPost = _filterPost.filter(post => post.acf.gallery_neighborhood.toUpperCase().includes(this.state.neighborhood.toUpperCase()));
    }

    if(this.state.style){
      _filterPost = _filterPost.filter(post => post.acf.gallery_style.toUpperCase().includes(this.state.style.toUpperCase()));
    }

    if(this.state.architect){
      _filterPost = _filterPost.filter(post => post.acf.gallery_architect.toUpperCase().includes(this.state.architect.toUpperCase()));
    }

    if(this.state.galleryData.length !== _filterPost.length){
      this.setState({
        galleryData: _filterPost,
        pageNum: 1,
        articleNum: _filterPost.length
      });
    }
  }

  _getCurrentPaginationPost = (data, num) => {
    let _currentPost = [...data];
    
    _currentPost = _currentPost.slice((num - 1) * _pageSize, (num - 1) * _pageSize + _pageSize);

    setTimeout(() => {
      const gBox = document.getElementsByClassName('fadeIn');

      for (const box of gBox) {
        box.classList.add('animated');
      }
    }, 0);
    
    return _currentPost;
  }

  _getOption = type => {
    let _arr = [];

    this.state.oriData.forEach(post => {
      if(!_arr.includes(post.acf[type])){
        _arr.push(post.acf[type])
      }
    });

    return _arr;
  }
  
  _clearAll = () => {
    this.setState({
      galleryData: this.state.oriData,
      pageNum: 1,
      articleNum: 0,
      searchKey: undefined,
      collection: undefined,
      decade: undefined,
      neighborhood: undefined,
      style: undefined,
      architect: undefined,
    })
  }

  render() {
    return (
      <Layout className="content-wrap" _temp="gallery">
        <div className="layout-container">
          <div className="content-pageWrap">
            <div className="content-pathWrap content-pathWrap_float">
              <a className="content-pathLink" href="javascript:;">品味建築</a>
            </div>

            <header className="Gallery-header">
              <div className="Gallery-row1">
                <h1 className="content-pageTitle">品味建築</h1>

                <div className="Gallery-ctl">
                  <a className={`Gallery-ctlLink${this.state.viewType === 0 ? ' active' : ''}`} href="javascript:;" onClick={() => this.setState({ viewType: 0 })}>
                    <span className="Gallery-ctlIcon">
                      <svg className="Gallery-svg" x="0px" y="0px" viewBox="0 0 44 44" style={{ enableBackground: 'new 0 0 44 44' }}>
                        <g>
                          <path d="M14.5,7L14.5,7v6.5c0,0.5-0.4,1-1,1H7c-0.5,0-1-0.4-1-1V7c0-0.5,0.4-1,1-1h6.5
                            C14.1,6,14.5,6.4,14.5,7z M25.8,7v6.5c0,0.5-0.4,1-1,1h-6.5c-0.5,0-1-0.4-1-1V7c0-0.5,0.4-1,1-1h6.5C25.4,6,25.8,6.4,25.8,7z
                            M37.1,7L37.1,7v6.5c0,0.5-0.4,1-1,1h-6.5c-0.5,0-1-0.4-1-1V7c0-0.5,0.4-1,1-1h6.5C36.7,6,37.1,6.4,37.1,7z M14.5,18.3L14.5,18.3
                            v6.5c0,0.5-0.4,1-1,1H7c-0.5,0-1-0.4-1-1v-6.5c0-0.5,0.4-1,1-1h6.5C14.1,17.4,14.5,17.8,14.5,18.3z M25.8,18.3v6.5c0,0.5-0.4,1-1,1
                            h-6.5c-0.5,0-1-0.4-1-1v-6.5c0-0.5,0.4-1,1-1h6.5C25.4,17.4,25.8,17.8,25.8,18.3z M37.1,18.3L37.1,18.3v6.5c0,0.5-0.4,1-1,1h-6.5
                            c-0.5,0-1-0.4-1-1v-6.5c0-0.5,0.4-1,1-1h6.5C36.7,17.4,37.1,17.8,37.1,18.3z M13.5,28.7c0.5,0,1,0.4,1,1v6.5c0,0.5-0.4,1-1,1H7
                            c-0.5,0-1-0.4-1-1v-6.5c0-0.5,0.4-1,1-1H13.5z M24.8,28.7c0.5,0,1,0.4,1,1v6.5c0,0.5-0.4,1-1,1h-6.5c-0.5,0-1-0.4-1-1v-6.5
                            c0-0.5,0.4-1,1-1H24.8z M36.1,28.7c0.5,0,1,0.4,1,1v6.5c0,0.5-0.4,1-1,1h-6.5c-0.5,0-1-0.4-1-1v-6.5c0-0.5,0.4-1,1-1H36.1z"/>
                        </g>
                      </svg>
                    </span>
                    <span className="Gallery-ctlInfo">Tile View</span>
                  </a>
                  {
                    this.state.pageData && this.state.pageData.acf.google_map_code ? (
                      <a className={`Gallery-ctlLink${this.state.viewType === 1 ? ' active' : ''}`} href="javascript:;" onClick={() => this.setState({ viewType: 1 })}>
                        <span className="Gallery-ctlIcon">
                          <svg className="Gallery-svg" x="0px" y="0px" viewBox="0 0 44 44" style={{ enableBackground: 'new 0 0 44 44' }}>
                            <g>
                              <path d="M35.8,23.2l6,12.3c0.2,0.5,0,0.9-0.6,0.9H3.7c-0.6,0-0.8-0.4-0.6-0.9l6-12.3
                                c0.1-0.2,0.4-0.4,0.6-0.4h5.1c0.2,0,0.4,0.1,0.5,0.2c0.3,0.4,0.7,0.8,1.1,1.2c0.3,0.4,0.7,0.7,1,1.1h-6.1c-0.2,0-0.5,0.2-0.6,0.4
                                l-4,8.2h31.5l-4-8.2c-0.1-0.2-0.4-0.4-0.6-0.4h-6.1c0.3-0.4,0.7-0.8,1-1.1c0.4-0.4,0.7-0.8,1.1-1.2c0.1-0.1,0.3-0.2,0.5-0.2h5.2
                                C35.5,22.9,35.8,23,35.8,23.2z M31.3,14.8c0,6.7-5.6,8-8.2,14.5c-0.2,0.5-1,0.5-1.2,0c-2.4-5.8-7.2-7.4-8.1-12.6
                                C12.9,11.5,16.4,6.5,21.6,6C26.9,5.5,31.3,9.7,31.3,14.8z M27.1,14.8c0-2.6-2.1-4.7-4.7-4.7s-4.7,2.1-4.7,4.7
                                c0,2.6,2.1,4.7,4.7,4.7S27.1,17.4,27.1,14.8z"/>
                            </g>
                          </svg>
                        </span>
                        <span className="Gallery-ctlInfo">Map view</span>
                      </a>
                    ) : null
                  }
                  <a className="Gallery-ctlLink" href="javascript:;" onClick={this._clearAll}>
                    <span className="Gallery-ctlIcon">
                      <svg className="Gallery-svg" x="0px" y="0px" viewBox="0 0 44 44" style={{ enableBackground: 'new 0 0 44 44' }}>
                        <path d="M40.9,30.2c-0.3-0.8-1.1-1.2-1.9-0.9l-1.9,0.6c1.2-2.4,1.8-5.1,1.8-7.8c0-9.9-8-17.9-17.9-17.9
                        C11,4.1,3,12.1,3,22s8,18,17.9,18c0.8,0,1.5-0.7,1.5-1.5S21.7,37,20.9,37c-8.3,0-15-6.7-15-15s6.7-15,15-15s15,6.7,15,15
                        c0,2.3-0.5,4.5-1.5,6.5l-0.7-2c-0.3-0.8-1.1-1.2-1.9-0.9c-0.8,0.3-1.2,1.1-0.9,1.9l1.7,5.3c0.2,0.6,0.8,1,1.4,1c0.2,0,0.3,0,0.5-0.1
                        l5.3-1.7C40.7,31.8,41.1,30.9,40.9,30.2z"/>
                      </svg>
                    </span>
                    <span className="Gallery-ctlInfo">Clear</span>
                  </a>
                </div>
              </div>
              
              <div className={`Gallery-row2${this.state.viewType === 0 ? '' : ' hidden'}`}>
                <div className="Gallery-search">
                  <Input value={this.state.searchKey} placeholder="請輸入建築物名稱" prefix={<Icon type="search" />} onChange={e => this.setState({ searchKey: e.target.value }, () => this._filterGalleryData())} />
                </div>
                {
                  this.state.oriData && this.state.oriData.length > 0 ? (
                  <div className="Gallery-selectWrap">
                    <span className="Gallery-select">
                      <Select value={this.state.collection} dropdownClassName="Gallery-option" placeholder="企劃" onChange={val => this.setState({ collection: val }, () => this._filterGalleryData())}>
                        {
                          this._getOption('gallery_collection').map(str => {
                            return <Option key={`gallery_collection${str}`} value={str}>{str}</Option>
                          })
                        }
                      </Select>
                    </span>

                    <span className="Gallery-select">
                      <Select value={this.state.neighborhood} dropdownClassName="Gallery-option" placeholder="地區" onChange={val => this.setState({ neighborhood: val }, () => this._filterGalleryData())}>
                        {
                          this._getOption('gallery_neighborhood').map(str => {
                            return <Option key={`gallery_neighborhood${str}`} value={str}>{str}</Option>
                          })
                        }
                      </Select>
                    </span>

                    <span className="Gallery-select">
                      <Select value={this.state.style} dropdownClassName="Gallery-option" placeholder="風格" onChange={val => this.setState({ style: val }, () => this._filterGalleryData())}>
                        {
                          this._getOption('gallery_style').map(str => {
                            return <Option key={`gallery_style${str}`} value={str}>{str}</Option>
                          })
                        }
                      </Select>
                    </span>

                    <span className="Gallery-select">
                      <Select value={this.state.architect} dropdownClassName="Gallery-option" placeholder="建築師" onChange={val => this.setState({ architect: val }, () => this._filterGalleryData())} >
                        {
                          this._getOption('gallery_architect').map(str => {
                            return <Option key={`gallery_architect${str}`} value={str}>{str}</Option>
                          })
                        }
                      </Select>
                    </span>

                    <span className="Gallery-select">
                      <Select value={this.state.decade} dropdownClassName="Gallery-option" placeholder="年代" onChange={val => this.setState({ decade: val }, () => this._filterGalleryData())}>
                        {
                          this._getOption('gallery_decade').map(str => {
                            return <Option key={`gallery_decade${str}`} value={str}>{str}</Option>
                          })
                        }
                      </Select>
                    </span>
                  </div>
                  ) : null
                }
              </div>
            </header>

            <div className={`Gallery-boxWrap${this.state.viewType === 0 ? ' active' : ''}`}>
              {
                this.state.galleryData && this.state.galleryData.length > 0 ? (
                  this._getCurrentPaginationPost(this.state.galleryData, this.state.pageNum).map((post, index) => {
                    return (
                      <article className="Gallery-box fadeIn" style={{ animationDelay: `${0.4 * index}s` }} key={`searchPost${post.link}`}>
                        <a className="link_default" href={post.link}>
                          {
                            post.acf.gallery_photo_1 ? <div className="Gallery-photo" style={{ backgroundImage: `url(${post.acf.gallery_photo_1})` }} /> : <div className="Gallery-photo" style={{ backgroundColor: '#efefef' }} />
                          }

                          <div className="Gallery-boxInfo">
                            <h1 className="Gallery-boxTitle" dangerouslySetInnerHTML={{ __html: post.title.rendered }} />

                            <h3 className="Gallery-boxSubTitle">{post.acf.gallery_neighborhood}</h3>
                          </div>
                        </a>
                      </article>
                    )
                  })
                ) : <article className="Gallery-box"><h4 className="Gallery-boxTitle">什麼都沒找到</h4></article>
              }
            </div>
            
            {
              this.state.googleCode ? (
                <div className={`Gallery-boxWrap${this.state.viewType === 1 ? ' active' : ''}`}>
                  <iframe src={this.state.googleCode} title="map view"></iframe>
                </div>
              ) : null
            }
            
            <Pagination className={`${this.state.viewType === 0 ? '' : 'hidden'}`} current={this.state.pageNum} total={this.state.articleNum} defaultPageSize={_pageSize} hideOnSinglePage={true} onChange={val => this.setState({ pageNum: val })} />
          </div>
        </div >
      </Layout >
    );
  }
}

// 準備綁定用的mapStateToProps方法，
// 將store中的items屬性綁到這個元件的props.items屬性上
const mapStateToProps = store => ({
  showMenu: store.default.showMenu,
});

// 準備綁定用的DispatchToProps方法，
// 只需要onFecthLoadItems這個方法
const mapDispatchToProps = dispatch => (
  bindActionCreators({ setGlobalUIStateByName }, dispatch)
);

// 連接Redux store
export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
