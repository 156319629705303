import React, { Component } from 'react';
import Slider from 'react-slick';

import leftArrow from 'images/icon/icon_back.svg';
import rightArrow from 'images/icon/icon_next.svg';
import CloseW from 'images/icon/close_w.svg';
import './LightBox.scss';

const LeftArrow = props => (<img className={props.className} src={leftArrow} onClick={props.onClick} alt="left" />);
const RightArrow = props => (<img className={props.className} src={rightArrow} onClick={props.onClick} alt="right" />);

class LightBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wrapId: '',
      showLightBox: false,
      imgArr: null,
      initImg: 0,
    }
  }

  componentDidUpdate() {
    const { _id } = this.props;
    const _articleContent = document.getElementById(_id);

    if (this.state.wrapId !== _id && _articleContent) {
      let _imgArr = [];
      const _imgEleArr = _articleContent.getElementsByTagName('img');
      const _that = this;

      _articleContent.classList.add('LightBox-imgWrap');

      for (let index = 0; index < _imgEleArr.length; index++) {
        _imgEleArr[index].addEventListener('click', function () {
          _that.setState({ showLightBox: true, initImg: index });
        });

        _imgArr.push({
          src: _imgEleArr[index].src,
          alt: _imgEleArr[index].alt
        })
      }

      this.setState({
        wrapId: _id,
        imgArr: _imgArr,
      });
    }
  }

  render() {
    const _img = this.state.imgArr;
    const settings = {
      customPaging: function (i) {
        return <div className="LightBox-thumb" style={{ backgroundImage: `url(${_img ? _img[i].src : '#efefef'})` }} />
      },
      dots: true,
      dotsClass: "LightBox-thumbWrap",
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: this.state.initImg,
      nextArrow: <RightArrow />,
      prevArrow: <LeftArrow />,
    };

    return (
      this.state.showLightBox ? (
        <div className={`LightBox-wrap${this.state.showLightBox ? ' active' : ''}`}>
          <div className="LightBox-content">
            {
              this.state.imgArr ? (
                <Slider {...settings}>
                  {
                    this.state.imgArr.map((obj, index) => {
                      return (
                        <div className="LightBox-photo" key={`LightBox${obj.src}${index}`}>
                          <img className="LightBox-img" src={obj.src} alt={obj.alt} />

                          <p className="LightBox-info">{obj.alt}</p>
                        </div>
                      )
                    })
                  }
                </Slider>
              ) : null
            }

            <a className="LightBox-close" href="javascript:;" onClick={() => this.setState({ showLightBox: false })}>
              <img src={CloseW} alt="close" />
            </a >
          </div >
        </div >
      ) : null
    );
  }
}

export default LightBox;