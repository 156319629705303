import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';

import { wpApi } from 'Service';
import * as parseUrl from 'Service/Global/parseUrl';
import Search from 'Routes/Search/Search';
import Single from 'Routes/Single/Single';
import Home from 'Routes/Home/Home';
import News from 'Routes/News/News';
import About from 'Routes/About/About';
import Event from 'Routes/Event/Event';
import EventPost from 'Routes/EventPost/EventPost';
import Gallery from 'Routes/Gallery/Gallery';
import GalleryPost from 'Routes/GalleryPost/GalleryPost';
import Product from 'Routes/Product/Product';
import ProductPost from 'Routes/ProductPost/ProductPost';
import Member from 'Routes/Member/Member';
import Project from 'Routes/Project/Project';
import Page from 'Routes/Page/Page';
import Form from 'Routes/Form/Form';

const w = window;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allPageData: null
    }
  }

  componentWillMount() {
    wpApi.getPagesData(data => this.setState({ allPageData: data }));
  }

  getPagePath = temp => {
    const oriUrl = parseUrl.isLocalEnv() ? 'http://kjmu.org.tw' : parseUrl.origin;
    const currentPage = this.state.allPageData.filter(page => page.template === temp)[0];
    const matchStr = currentPage ? decodeURIComponent(currentPage.link.replace(oriUrl, '')) : '';

    return `${matchStr}`;
  }

  render() {
    return (
      this.state.allPageData ? (
        <BrowserRouter>
          {
            w.originTemp ? (
              <Route component={Form} />
            ) : (
                parseUrl.queryByName('s') || parseUrl.queryByName('s') === '' ? (
                  <Route component={Search} />
                ) : (
                    w.postId ? (
                      <Route component={Single} />
                    ) : (
                        w.pageId ? (
                          <Route component={Page} />
                        ) : (
                            <div className="content-router">
                              <Route path={this.getPagePath('front-page.php')} exact component={Home} />

                              <Route path={this.getPagePath('news.php')} exact component={News} />
                              <Route path={'/tag/:name'} exact component={News} />
                              <Route path={'/author/:name'} exact component={News} />

                              <Route path={this.getPagePath('about.php')} exact component={About} />

                              <Route path={this.getPagePath('event.php')} exact component={Event} />

                              <Route path={'/event/:name'} exact component={EventPost} />

                              <Route path={this.getPagePath('gallery.php')} exact component={Gallery} />

                              <Route path={'/gallery/:name'} exact component={GalleryPost} />

                              <Route path={this.getPagePath('product.php')} exact component={Product} />

                              <Route path={'/product/:name'} exact component={ProductPost} />

                              <Route path={this.getPagePath('member.php')} exact component={Member} />
                              
                              <Route path={'/project/:name'} exact component={Project} />
                            </div>
                          )
                      )
                  )
              )
          }
        </BrowserRouter>
      ) : null
    );
  }
}

// 準備綁定用的mapStateToProps方法，
// 將store中的items屬性綁到這個元件的props.items屬性上
const mapStateToProps = store => ({
});

// 準備綁定用的DispatchToProps方法，
// 只需要onFecthLoadItems這個方法
const mapDispatchToProps = dispatch => (
  bindActionCreators({}, dispatch)
);

// 連接Redux store
export default connect(mapStateToProps, mapDispatchToProps)(App);
