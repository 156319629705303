import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Slider from 'react-slick';

import { setGlobalUIStateByName } from '../../Actions';
import { wpApi } from '../../Service';
import Layout from '../../Containers/Layout/Layout';
import LightBox from '../../Containers/LightBox/LightBox';
import './ProductPost.scss';

const _w = window;

class ProductPost extends Component {
  constructor(props) {
    super(props);

    this.state = {
      productData: null,
      productAcf: null,
    }
  }

  componentWillMount() {
    wpApi.getPostDataByType(`product/${_w.productPostId}`, data => {
      this.setState({
        productData: data,
        productAcf: data.acf,
      });
    })
  }

  _getHeroPhoto = () => {
    let _photoArr = [];

    for (let index = 1; index < 8; index++) {
      if (this.state.productAcf[`product_img_${index}`]) {
        _photoArr.push(
          <div key={`productHero${index}`}><div className="productPost-heroPhoto" style={{ backgroundImage: `url(${this.state.productAcf[`product_img_${index}`]})` }} /></div>
        )
      }
    }

    return _photoArr;
  }

  render() {
    const carouselSetting = {
      infinite: true,
      speed: 1000,
      dots: true,
      arrows: false,
      fade: true,
      customPaging: index => {
        return this.state.productAcf[`product_img_${index + 1}`] ? <div key={`productHeroCtl${index}`} className="productPost-heroCtl" style={{ backgroundImage: `url(${this.state.productAcf[`product_img_${index + 1}`]})` }} /> : <button key={`productHeroCtl${index}`}>{index}</button>;
      },
      responsive: [
        {
          breakpoint: 768,
          settings: {
            infinite: true,
            customPaging: index => {
              return <button key={`productHeroDot${index}`}>{index}</button>;
            },
          }
        },
      ]
    };

    return (
      <Layout className="content-wrap" _temp="productPost">
        <div className="layout-container">
          {
            this.state.productData ? (
              <div className="content-pageWrap">
                <div className="content-pathWrap content-pathWrap_float">
                  <a className="content-pathLink" href="/訂購出版品/">訂購出版品</a>
                  <a className="content-pathLink" href="javascript:;" dangerouslySetInnerHTML={{ __html: this.state.productData.title.rendered }} />
                </div>

                <div className="productPost-infoRow">
                  <Slider className="productPost-photoWrap" {...carouselSetting}>
                    {this._getHeroPhoto()}
                  </Slider>

                  <div className="productPost-info">
                    <h1 className="productPost-infoTitle" dangerouslySetInnerHTML={{ __html: this.state.productData.title.rendered }} />

                    <div className="productPost-infoTable" dangerouslySetInnerHTML={{ __html: this.state.productAcf.product_attribute }} />

                    {
                      this.state.productAcf.product_type_1 ? <a className="content-toknow" href={this.state.productAcf.product_type_1} target="_blank" rel="noopener noreferrer">/ 訂購紙本書 /</a> : null
                    }

                    {
                      this.state.productAcf.product_type_2 ? <a className="content-toknow" href={this.state.productAcf.product_type_2} target="_blank" rel="noopener noreferrer">/ 訂購電子書 /</a> : null
                    }
                  </div>
                </div>
              </div>
            ) : null
          }
          {
            this.state.productData ? <div id="articleContent" className="content-article" dangerouslySetInnerHTML={{ __html: this.state.productData.content.rendered }} /> : null
          }
        </div>
        <LightBox _id={`articleContent`} />
      </Layout >
    );
  }
}

// 準備綁定用的mapStateToProps方法，
// 將store中的items屬性綁到這個元件的props.items屬性上
const mapStateToProps = store => ({
  showMenu: store.default.showMenu,
});

// 準備綁定用的DispatchToProps方法，
// 只需要onFecthLoadItems這個方法
const mapDispatchToProps = dispatch => (
  bindActionCreators({ setGlobalUIStateByName }, dispatch)
);

// 連接Redux store
export default connect(mapStateToProps, mapDispatchToProps)(ProductPost);
