import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { setGlobalUIStateByName } from '../../Actions';
import { wpApi, service } from '../../Service';
import Layout from '../../Containers/Layout/Layout';
import ShareList from '../../Containers/ShareList/ShareList';
import LightBox from '../../Containers/LightBox/LightBox';
import './EventPost.scss';

const _w = window;

class EventPost extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eventData: null,
      eventAcf: null,
      tagsData: null,
      socialTab: 'other',
      tagsPost: [],
      animateTab: '',
    }
  }

  componentWillMount() {
    wpApi.getPostDataByType(`event/${_w.eventId}`, data => {
      this.setState({
        eventData: data,
        eventAcf: data.acf,
      }, () => {
        if (data.tags && data.tags.length > 0) {
          this._getPostForTag(data.tags);
        }
      })
    });
  }

  _getPostForTag = tagsData => {
    tagsData.forEach(tagId => {
      wpApi.getApi(`event?tags=${tagId}&_embed`, data => {
        let _postArr = [];

        data.forEach(post => {
          if (this.state.tagsPost.every(obj => { return post.id !== obj.id }) && post.id !== _w.eventId) {
            _postArr.push(post);
          }
        })

        this.setState({ tagsPost: this.state.tagsPost.concat(_postArr) });
      })
    });
  }

  _getEventFiles = () => {
    let _eventFiles = [];

    for (let index = 0; index < 5; index++) {
      if (this.state.eventAcf[`event_file_${index}`]) {
        _eventFiles.push(
          <a className="link_file" href={this.state.eventAcf[`event_file_${index}`]} key={`eventFilts${this.state.eventAcf[`event_file_${index}`]}${index}`}>
            {this.state.eventAcf[`event_file_name_${index}`] ? this.state.eventAcf[`event_file_name_${index}`] : `附加檔案${index + 1}`}
          </a>
        )
      }
    }

    return _eventFiles;
  }

  render() {
    return (
      <Layout className="content-wrap" _temp="eventPost">
        {
          this.state.eventData ? (
            <div className="content-hero content-hero_full">
              <div className="content-pathWrap content-pathWrap_float">
                <a className="content-pathLink" href="/活動行程/">活動行程</a>
                <a className="content-pathLink" href="javascript:;" dangerouslySetInnerHTML={{ __html: this.state.eventData.title.rendered }} />
              </div>

              {
                this.state.eventData._embedded && this.state.eventData._embedded['wp:featuredmedia'] ? (
                  <div className="content-heroPhoto content-heroPhoto_full" style={{ backgroundImage: `url(${this.state.eventData._embedded['wp:featuredmedia'][0].source_url})` }} />
                ) : null
              }

              <h1 className="content-heroTitle" dangerouslySetInnerHTML={{ __html: this.state.eventData.title.rendered }} />
            </div>
          ) : null
        }

        {
          this.state.eventData ? (
            <div id="printContent" className="layout-container">
              <div className="content-article">
                <div className="eventPost-infoWrap">

                  <div className="content-tagWrap">
                    <p className="content-tagTitle">
                      活動日期
                      <span className="content-tagDate">
                        {`${service.getTimeByString(this.state.eventAcf.event_date_start, '.')} ~ ${service.getTimeByString(this.state.eventAcf.event_date_end, '.')}`}
                      </span>
                    </p>
                  </div>
                  {
                    this.state.tagsData ? (
                      <div className="content-tagWrap">
                        <p className="content-tagTitle">標籤</p>

                        <ul className="content-tagList">
                          {
                            this.state.tagsData.map(obj => {
                              return <li key={`tagList${obj.id}`}><a className="btn" href={obj.link}>{obj.name}</a></li>
                            })
                          }
                        </ul>
                      </div>
                    ) : null
                  }

                  <div id="articleContent" className="Single-article" dangerouslySetInnerHTML={{ __html: this.state.eventData.content.rendered }} />
                </div>
              </div>
            </div>
          ) : null
        }
        {
          this.state.eventData ? (
            <div className="content-socialWrap content-socialWrap_gray">
              <p className="content-shareTitle">分享</p>

              <ShareList _title={this.state.eventData.title.rendered} />
            </div>
          ) : null
        }

        {/* 意見調查 */}
        {
          this.state.eventAcf && this.state.eventAcf.event_form ? (
            <div className="content-rowBg">
              <div className="layout-container">
                <div className="content-row">
                  <h1 className="content-title">{this.state.eventAcf.event_form_title}</h1>

                  <div className="content-iframe content-iframe_event">
                    <iframe width="100%" height="500" src={this.state.eventAcf.event_form} frameBorder="0" title={this.state.eventAcf.event_form_title}></iframe>
                  </div>
                </div>
              </div>
            </div>
          ) : null
        }
        {
          this.state.eventAcf ? (
            <div className="content-rowBg">
              <div className="layout-container">
                <div className="content-row">
                  <div className="eventPost-tab">
                    <div className="tab-ctlWrap">
                      <a className={`tab-link${this.state.socialTab === 'other' ? ' active' : ''}`} href="javascript:;" onClick={() => this.setState({ socialTab: 'other', animateTab: ' fadeIn animated' })}>系列活動</a>
                      {
                        this.state.eventAcf.event_video_code ? (
                          <a className={`tab-link${this.state.socialTab === 'viedo' ? ' active' : ''}`} href="javascript:;" onClick={() => this.setState({ socialTab: 'viedo', animateTab: ' fadeIn animated' })}>活動影像</a>
                        ) : null
                      }
                      {
                        this._getEventFiles().length > 0 ? (
                          <a className={`tab-link${this.state.socialTab === 'file' ? ' active' : ''}`} href="javascript:;" onClick={() => this.setState({ socialTab: 'file', animateTab: ' fadeIn animated' })}>附加檔案</a>
                        ) : null
                      }
                    </div>

                    <div className={`tab-wrap${this.state.animateTab}`} onAnimationEnd={() => this.setState({ animateTab: '' })}>
                      <div className="tab-content tab-content_full">
                        {
                          this.state.socialTab === 'other' && this.state.tagsPost.length > 0 ? (
                            <div className="content-morePost">
                              {
                                service.getRamdonPost(this.state.tagsPost, 3).map(post => {
                                  return (
                                    <div className="content-col content-col_eventPost" key={`tagsPost${post.id}`}>
                                      <a className="link_default" href={post.link}>
                                        <div className="content-photo" style={{ backgroundImage: `url(${post._embedded['wp:featuredmedia'] ? post._embedded['wp:featuredmedia'][0].source_url : '#efefef'})` }}></div>
                                      </a>

                                      <div className="content-info">
                                        <a className="link_default" href={post.link}>
                                          <h3 className="content-colTitle content-colTitle_full" dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
                                        </a>

                                        <div className="content-preview content-preview_min" dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
                                      </div>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          ) : null
                        }
                        {
                          this.state.socialTab === 'viedo' ? (
                            <div className="content-video">
                              <iframe width="100%" height="100%" src={this.state.eventAcf.event_video_code} frameBorder="0" allow="autoplay; encrypted-media" title="理念影片" allowFullScreen></iframe>
                            </div>
                          ) : null
                        }
                        {
                          this.state.socialTab === 'file' ? (
                            <div className="eventPost-file">{this._getEventFiles()}</div>
                          ) : null
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null
        }
        {
          this.state.eventData ? (
            <div className="layout-container">
              <div className="content-fbComments">
                <div className="fb-comments" data-href={this.state.eventData.link} data-numposts="5" />
              </div>
            </div>
          ) : null
        }

        <LightBox _id="articleContent" />
      </Layout>
    );
  }
}

// 準備綁定用的mapStateToProps方法，
// 將store中的items屬性綁到這個元件的props.items屬性上
const mapStateToProps = store => ({
  showMenu: store.default.showMenu,
});

// 準備綁定用的DispatchToProps方法，
// 只需要onFecthLoadItems這個方法
const mapDispatchToProps = dispatch => (
  bindActionCreators({ setGlobalUIStateByName }, dispatch)
);

// 連接Redux store
export default connect(mapStateToProps, mapDispatchToProps)(EventPost);
