import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Select } from 'antd';

import { setGlobalUIStateByName } from '../../Actions';
import { wpApi } from '../../Service';
import Layout from '../../Containers/Layout/Layout';
import './Member.scss';

const Option = Select.Option;

class Member extends Component {
  constructor(props) {
    super(props);

    this.state = {
      memberData: null,
      currentMember: null
    }
  }

  componentWillMount() {
    this._getAllMemberData('member');
  }

  componentDidUpdate() {
    this._setMemberAnimate();
  }

  _setMemberAnimate = () => {
    for (let index = 0; index < 20; index++) {
      const _ele = document.getElementById(`a-member${index}`);

      if (_ele) {
        setTimeout(() => {
          _ele.classList.add('fadeIn');
        }, 500 * (index + 1));
      } else {
        const _ele = document.getElementById('a-member19');

        setTimeout(() => {
          _ele.classList.add('fadeIn');
        }, 500 * (index + 1));
        break;
      }
    }
  }

  _changeMemberYear = val => {
    const _memberEle = document.getElementsByClassName('j-member');

    Array.prototype.forEach.call(_memberEle, ele => {
      ele.classList.remove('fadeIn');
    });

    this.setState({ currentMember: this.state.memberData.filter(obj => obj.id === val)[0] }, () => {
      this._setMemberAnimate();
    });
  }

  _getAllMemberData = postType => {
    wpApi.getPostDataByType(postType, data => {
      const _sortData = data.sort((a, b) => { return new Date(b.date) - new Date(a.date) });

      this.setState({
        memberData: _sortData,
        currentMember: _sortData[0],
      })
    });
  }

  _getMemberList = acf => {
    let _list = [];

    if (acf) {
      for (let index = 1; index < 19; index++) {
        if (acf[`about_member_name_${index}`] && acf[`about_member_name_${index}`] !== '') {
          _list.push(
            <div id={`a-member${index}`} className="j-member member-col animated_75" key={`memberCol${acf[`about_member_profile_${index}`]}${index}`}>
              <div className="member-photo" style={{ backgroundImage: acf[`about_member_profile_${index}`] ? `url(${acf[`about_member_profile_${index}`]})` : '' }} />
              <h3 className="member-colTitle">{acf[`about_member_name_${index}`]}</h3>

              <p className="member-colInfo" dangerouslySetInnerHTML={{ __html: acf[`about_member_resume_${index}`] }} />
                {/* {
                  this._formatToHtml(acf[`about_member_resume_${index}`]).map((str, index) => {
                    return <span key={`${str}${index}`}>{str}</span>
                  })
                }
              </p> */}
            </div>
          )
        }
      }
    }

    return _list;
  }

  _formatToHtml = info => {
    let _infoArr1 = [];
    let _infoArr2 = [];

    info.split('&lt;br /&gt;').forEach(str => {
      _infoArr1 = [..._infoArr1, ...str.split('&lt;br/&gt;')]
    });

    _infoArr1.forEach(str => {
      _infoArr2 = [..._infoArr2, ...str.split('&lt;br&gt;')]
    });

    return _infoArr2;
  }

  render() {
    const _acf = this.state.currentMember ? this.state.currentMember.acf : null;

    return (
      <Layout className="content-wrap" _temp="member">
        <div className="layout-container">
          <div className="content-pageWrap">
            <div className="content-pathWrap content-pathWrap_float">
              <a className="content-pathLink" href="javascript:;">關於我們</a>
              <a className="content-pathLink" href="javascript:;">團隊成員</a>
            </div>

            {
              this.state.memberData ? (
                <div className="member-wrap">
                  <div className="member-select">
                    <Select defaultValue={this.state.currentMember.id} onChange={this._changeMemberYear}>
                      {
                        this.state.memberData.map(page => {
                          return (
                            <Option value={page.id} key={`memberSelect${page.id}`}>{page.title.rendered}</Option>
                          )
                        })
                      }
                    </Select>
                  </div>

                  <div className="member-content">
                    <h3 className="member-title" dangerouslySetInnerHTML={{ __html: this.state.currentMember.title.rendered }} />

                    <p className="member-time">(按姓氏筆畫順序排列)</p>

                    <div className="member-row">
                      <div id="a-member0" className="j-member member-col animated_75">
                        <div className="member-photo" style={{ backgroundImage: `url(${_acf.about_member_chairman_profile})` }}></div>
                        <h3 className="member-colTitle">{_acf.about_member_chairman_name}</h3>

                        <p className="member-colInfo" dangerouslySetInnerHTML={{ __html: _acf.about_member_chairman_resume }} />
                          {/* {
                            this._formatToHtml(_acf.about_member_chairman_resume).map((str, index) => {
                              return <span key={`${str}${index}`}>{str}</span>
                            })
                          }
                        </p> */}
                      </div>
                    </div>

                    <div className="member-row">
                      {this._getMemberList(_acf)}
                    </div>

                    <div className="member-row">
                      <div id="a-member19" className="j-member member-col animated_75">
                        <div className="member-photo" style={{ backgroundImage: `url(${_acf.about_member_ceo_profile})` }}></div>
                        <h3 className="member-colTitle">{_acf.about_member_ceo_name}</h3>

                        <p className="member-colInfo" dangerouslySetInnerHTML={{ __html: _acf.about_member_ceo_resume }} />
                          {/* {
                            this._formatToHtml(_acf.about_member_ceo_resume).map((str, index) => {
                              return <span key={`${str}${index}`}>{str}</span>
                            })
                          }
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null
            }
          </div>
        </div>
      </Layout>
    );
  }
}

// 準備綁定用的mapStateToProps方法，
// 將store中的items屬性綁到這個元件的props.items屬性上
const mapStateToProps = store => ({
  showMenu: store.default.showMenu,
});

// 準備綁定用的DispatchToProps方法，
// 只需要onFecthLoadItems這個方法
const mapDispatchToProps = dispatch => (
  bindActionCreators({ setGlobalUIStateByName }, dispatch)
);

// 連接Redux store
export default connect(mapStateToProps, mapDispatchToProps)(Member);
