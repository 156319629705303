import React from 'react';
import ReactDOM from 'react-dom';
import { compose, createStore, combineReducers, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import 'core-js';

import * as reducers from 'Reducers/GlobalUI';
import * as serviceWorker from './serviceWorker';
import App from './App';

const w = window;

// 開發環境載入logger middleware
const middlewares = [];
if (process.env.NODE_ENV === `development`) {
  const logger = createLogger({
    collapsed: (getState, action, logEntry) => !logEntry.error
  });
  middlewares.push(thunk, logger);
} else {
  middlewares.push(thunk);
}
let allMiddleware = applyMiddleware(...middlewares);

// 這裡作合併reducer的動作
const reducer = combineReducers({ ...reducers });

// 使用redux dev tools
const devToolsExtension = w.devToolsExtension;
if (typeof devToolsExtension === 'function') {
  allMiddleware = compose(allMiddleware, devToolsExtension());
}

// 如果要正常使用是使用 const store = createStore(reducer, applyMiddleware())
const store = createStore(reducer, allMiddleware);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
