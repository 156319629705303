import React, { Component } from 'react';

import { wpApi } from '../../Service';
import CloseW from '../../images/Icon/close_w.svg';
import './Menu.scss';

const _w = window;

class Menu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tagsData: [],
      inputSearch: '',
      menuLists: null,
    }
  }

  componentDidMount = () => {
    wpApi.getTagsData(data => {
      const _tagsData = data.sort((a, b) => { return b.count - a.count }).slice(0, 8);

      this.setState({ tagsData: _tagsData });
    })
  }

  _changeMenuLists = index => {
    if(this.state.menuLists === index){
      this.setState({ menuLists: null });
    } else {
      this.setState({ menuLists: index });
    }
  }

  _hrefToSearchPage = e => {
    if (e.key === 'Enter') {
      _w.location.href = `${_w.location.origin}/?s=${this.state.inputSearch.replace(' ', '+')}`
    }
  }

  render() {
    const { _showMenu, _toggleMenu, _mainMenu } = this.props;

    return (
      <div className={`menu-bg${_showMenu ? ` j-${_showMenu} active` : ''}`}>
        <div className="menu-wrap">
          <a className="menu-close" href="javascript:;" onClick={() => _toggleMenu('close')}>
            <img src={CloseW} alt="close" />
          </a>

          <div className="menu-nav">
            <ul className="menu-lists">
              {
                _mainMenu.map((list, index) => {
                  return (
                    <li key={`manuLevelOne${list.ID}`}>
                      {
                        list.children.length > 0 ? (
                          <span className={`menu-linkWrap${this.state.menuLists === index ? ' active' : ''}`}>
                            <a className="menu-link" onClick={() => this._changeMenuLists(index)} href="javascript:;">{list.title}</a>

                            <ul className="menu-lists1">
                              {
                                list.children.map(chird => {
                                  return (
                                    <li key={`quickMenu${chird.ID}`}>
                                      <a className="menu-link" href={chird.url}>{chird.title}</a>
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </span>
                        ) : (
                            <a className="menu-link menu-link_unline" href={list.url}>{list.title}</a>
                          )
                      }
                    </li>
                  )
                })
              }
            </ul>
          </div>

          <div className="menu-search">
            <div className="menu-inputWrap">
              <div className="input_searchWrap">
                <input className="input input_w input_search" type="text" placeholder="搜尋文章或活動" value={this.state.inputSearch} onChange={e => this.setState({ inputSearch: e.target.value })} onKeyPress={this._hrefToSearchPage} />
                <a className="input_searchIcon" onClick={() => this._hrefToSearchPage({key: 'Enter'})} href="javascript:;">
                  <svg width="38px" height="38px" viewBox="0 0 38 38">
                    <g stroke="none" strokeWidth="1" fillRule="evenodd">
                      <g transform="translate(-6.000000, -6.000000)">
                        <path fill="#fff" d="M28.4575,33.35 C25.1425,33.35 22.1355,32.02 19.9375,29.86 C19.9345,29.86 19.9335,29.85 19.9305,29.85 C19.9255,29.85 19.9185,29.84 19.9135,29.84 C17.6735,27.63 16.2805,24.56 16.2805,21.18 C16.2805,14.46 21.7435,9 28.4575,9 C35.1715,9 40.6345,14.46 40.6345,21.18 C40.6345,27.89 35.1715,33.35 28.4575,33.35 Z M28.4575,6 C20.0895,6 13.2805,12.81 13.2805,21.18 C13.2805,24.86 14.6015,28.24 16.7905,30.87 L6.4395,41.22 C5.8535,41.81 5.8535,42.75 6.4395,43.34 C6.7325,43.63 7.1165,43.78 7.5005,43.78 C7.8845,43.78 8.2675,43.63 8.5605,43.34 L18.9295,32.97 C21.5365,35.08 24.8505,36.35 28.4575,36.35 C36.8265,36.35 43.6345,29.54 43.6345,21.18 C43.6345,12.81 36.8265,6 28.4575,6 Z" transform="translate(24.817250, 24.890000) scale(-1, 1) translate(-24.817250, -24.890000) "></path>
                      </g>
                    </g>
                  </svg>
                </a>
              </div>
            </div>

            <div className="menu-info">
              <h3 className="menu-keyword">熱門關鍵字</h3>

              <div className="menu-tagWrap">
                {
                  this.state.tagsData.map(tag => {
                    return <a className="menu-tag" href={tag.link} key={`menuTagList${tag.id}`}>{tag.name}</a>
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Menu;
