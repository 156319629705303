const _w = window;

export const getTimeByString = (str, separator = null) => {
  const _date = new Date(str);

  return separator ? `${_date.getUTCFullYear()}${separator}${_date.getUTCMonth() + 1}${separator}${_date.getUTCDate()}` : _date.toDateString();
}

export const getRamdonPost = (arr, max) => {
  if (arr.length <= max) {
    return arr;
  } else {
    let _newArr = [...arr];

    for (let i = _newArr.length; i > max; i--) {
      _newArr.splice(Math.floor(Math.random() * _newArr.length), 1);
    }

    return _newArr;
  }
}

export const printDiv = eleId => {
  document.getElementsByClassName('body-wrap')[0].innerHTML = document.getElementById(eleId).innerHTML;

  _w.print();
  _w.location.reload();
}