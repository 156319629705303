import React, { Component } from 'react';

import './Footer.scss';

class Footer extends Component {
  componentDidMount = () => {
    document.getElementById('orderClose').addEventListener('click', function () {
      document.getElementById('mc_embed_signup').classList.remove('j-order', 'active');
    });
  }

  addOrderForm = () => {
    document.getElementById('mc_embed_signup').classList.add('j-order', 'active');
  }

  render() {
    return (
      <footer>
        <div className="footer-wrap">
          <div className="layout-container">
            <div className="footer-row">
              <div className="footer-col">
                <h2 className="footer-title">財團法人空間母語文化藝術基金會</h2>
                <p className="footer-info">Spatial Native Language Foundation of Arts and Culture</p>
                <div className="footer-btnWrap">
                  <a className="btn" href="#" onClick={this.addOrderForm}>訂閱電子報</a>
                  <a className="btn" href="/support/">贊助基金會</a>
                </div>
              </div>

              <div className="footer-col">
                <h2 className="footer-title">聯絡資訊</h2>

                <ul className="footer-list">
                  <li>
                    <span className="footer-list1">聯絡電話</span>
                    <span className="footer-list2">|</span>
                    <span className="footer-list3">+886-2-2751-3256</span>
                  </li>
                  <li>
                    <span className="footer-list1">服務信箱</span>
                    <span className="footer-list2">|</span>
                    <span className="footer-list3">contact@kjmu.org.tw</span>
                  </li>
                  <li>
                    <span className="footer-list1">地址</span>
                    <span className="footer-list2">|</span>
                    <span className="footer-list3">10062 台北市中正區仁愛路二段71號3樓之6</span>
                    
                  </li>
                </ul>
              </div>

              <div className="footer-col">
                <p className="footer-cr">© 空間母語 2018. All rights reserved.<br />
                  <a className="link_default" href="/terms-conditions/">Terms & Conditions</a><br />
                  <a className="link_default" href="/privacy-policy/">Privacy Policy</a><br />
                  <a className="link_default" href="/sitemap.xml/">Sitemap</a></p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;