export const SET_GLOBALUI_STATE_BY_NAME = 'SET_GLOBALUI_STATE_BY_NAME';

// ==============================================================
// UI
// ==============================================================
// set UI state by name
export const setGlobalUIStateByName = (name: String, val: any) => (
  {
    type: SET_GLOBALUI_STATE_BY_NAME,
    payload: { name: name, val: val }
  }
)
