import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Slider from 'react-slick';

import { setGlobalUIStateByName } from '../../Actions';
import { wpApi } from '../../Service';
import Layout from '../../Containers/Layout/Layout';
import ShareList from '../../Containers/ShareList/ShareList';
import LightBox from '../../Containers/LightBox/LightBox';
import './GalleryPost.scss';

const _w = window;
const carouselSetting = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: true,
  speed: 2000,
  dots: true,
  arrows: false,
  fade: true,
};

class GalleryPost extends Component {
  constructor(props) {
    super(props);

    this.state = {
      galleryData: null,
      galleryAcf: null,
    }
  }

  componentWillMount() {
    wpApi.getPostDataByType(`gallery/${_w.galleryId}`, data => {
      this.setState({
        galleryData: data,
        galleryAcf: data.acf,
      });
    })
  }

  _getHeroPhoto = () => {
    let _photoArr = [];

    for (let index = 1; index < 6; index++) {
      if (this.state.galleryAcf[`gallery_photo_${index}`]) {
        _photoArr.push(
          <div key={`galleryHero${index}`}><div className="content-heroPhoto" style={{ backgroundImage: `url(${this.state.galleryAcf[`gallery_photo_${index}`]})` }} /></div>
        )
      }
    }

    return _photoArr;
  }

  render() {
    return (
      <Layout className="content-wrap" _temp="galleryPost">
        {
          this.state.galleryData ? (
            <div className="layout-container">
              <div className="content-hero">
                <div className="content-pathWrap content-pathWrap_float">
                  <a className="content-pathLink" href="/品味建築/">品味建築</a>
                  <a className="content-pathLink" href="javascript:;" dangerouslySetInnerHTML={{ __html: this.state.galleryData.title.rendered }} />
                </div>

                <h1 className="content-title content-title_left" dangerouslySetInnerHTML={{ __html: this.state.galleryData.title.rendered }} />

                {
                  this.state.galleryAcf.gallery_photo_1 ? (
                    <Slider className="layout-container" {...carouselSetting}>
                      {this._getHeroPhoto()}
                    </Slider>
                  ) : null
                }
              </div>

              <div className="content-pageWrap">
                <div className="galleryPost-infoWrap">
                  <div className="galleryPost-info">
                    <div className="galleryPost-infoRow">
                      <h3 className="galleryPost-infoTitle">建築物名稱</h3>

                      <p className="galleryPost-infoText" dangerouslySetInnerHTML={{ __html: this.state.galleryData.title.rendered }} />
                    </div>

                    <div className="galleryPost-infoRow">
                      <h3 className="galleryPost-infoTitle">地址</h3>

                      <p className="galleryPost-infoText" dangerouslySetInnerHTML={{ __html: this.state.galleryAcf.gallery_address }} />
                    </div>


                    <div className="galleryPost-infoRow">
                      <h3 className="galleryPost-infoTitle">建築師</h3>

                      <p className="galleryPost-infoText" dangerouslySetInnerHTML={{ __html: this.state.galleryAcf.gallery_architect }} />
                    </div>


                    <div className="galleryPost-infoRow">
                      <h3 className="galleryPost-infoTitle">風格</h3>

                      <p className="galleryPost-infoText" dangerouslySetInnerHTML={{ __html: this.state.galleryAcf.gallery_style }} />
                    </div>


                    <div className="galleryPost-infoRow">
                      <h3 className="galleryPost-infoTitle">地區</h3>

                      <p className="galleryPost-infoText" dangerouslySetInnerHTML={{ __html: this.state.galleryAcf.gallery_neighborhood }} />
                    </div>


                    <div className="galleryPost-infoRow">
                      <h3 className="galleryPost-infoTitle">年代</h3>

                      <p className="galleryPost-infoText" dangerouslySetInnerHTML={{ __html: this.state.galleryAcf.gallery_decade }} />
                    </div>
                  </div>

                  <div className="galleryPost-map">
                    <iframe src={this.state.galleryAcf.gallery_map} title={this.state.galleryData.title.rendered} frameBorder="0"></iframe>
                  </div>
                </div>
              </div>
            </div>
          ) : null
        }

        {
          this.state.galleryData ? (
            <div id="printContent" className="layout-container">
              <div className="content-article" dangerouslySetInnerHTML={{ __html: this.state.galleryData.content.rendered }} />
            </div>
          ) : null
        }
        {
          this.state.galleryData ? (
            <div className="content-socialWrap content-socialWrap_gray">
              <p className="content-shareTitle">分享</p>

              <ShareList _title={this.state.galleryData.title.rendered} />
            </div>
          ) : null
        }
        <LightBox _id={`printContent`} />
      </Layout >
    );
  }
}

// 準備綁定用的mapStateToProps方法，
// 將store中的items屬性綁到這個元件的props.items屬性上
const mapStateToProps = store => ({
  showMenu: store.default.showMenu,
});

// 準備綁定用的DispatchToProps方法，
// 只需要onFecthLoadItems這個方法
const mapDispatchToProps = dispatch => (
  bindActionCreators({ setGlobalUIStateByName }, dispatch)
);

// 連接Redux store
export default connect(mapStateToProps, mapDispatchToProps)(GalleryPost);
