import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { setGlobalUIStateByName } from '../../Actions';
import { wpApi } from '../../Service';
import Layout from '../../Containers/Layout/Layout';
import LightBox from '../../Containers/LightBox/LightBox';
import './Page.scss';

class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageData: null,
    }
  }

  componentWillMount() {
    wpApi.getPageDataById(window.pageId, data => this.setState({ pageData: data }));
  }

  render() {
    return (
      <Layout className="content-wrap" _temp="page">
        {
          this.state.pageData ? (
            <div className="layout-container">
              <div className="content-hero">
                <div className="content-pathWrap content-pathWrap_float">
                  <a className="content-pathLink" href="javascript:;" dangerouslySetInnerHTML={{ __html: this.state.pageData.title.rendered }} />
                </div>

                <h1 className="content-title content-title_left" dangerouslySetInnerHTML={{ __html: this.state.pageData.title.rendered }} />

                {
                  this.state.pageData._embedded['wp:featuredmedia'] ? <div className="content-heroPhoto" style={{ backgroundImage: `url(${this.state.pageData._embedded['wp:featuredmedia'][0].source_url})` }}>
                  </div> : null
                }

                <div id="articleContent" className={`${this.state.pageData.title.rendered === '聯絡基金會' ? 'content-article content-article_full' : 'content-article'}`} dangerouslySetInnerHTML={{ __html: this.state.pageData.content.rendered }} />
              </div>
            </div>
          ) : null
        }
        <LightBox _id={`articleContent`} />
      </Layout>
    );
  }
}

// 準備綁定用的mapStateToProps方法，
// 將store中的items屬性綁到這個元件的props.items屬性上
const mapStateToProps = store => ({
  showMenu: store.default.showMenu,
});

// 準備綁定用的DispatchToProps方法，
// 只需要onFecthLoadItems這個方法
const mapDispatchToProps = dispatch => (
  bindActionCreators({ setGlobalUIStateByName }, dispatch)
);

// 連接Redux store
export default connect(mapStateToProps, mapDispatchToProps)(Page);
