import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { setGlobalUIStateByName } from '../../Actions';
import { wpApi, service } from '../../Service';
import Layout from '../../Containers/Layout/Layout';
import ShareList from '../../Containers/ShareList/ShareList';
import LoadMore from '../../images/Icon/load_more.svg';
import InstagramLab from '../../Compoments/Instagram/Instagram';
import './Project.scss';

const _w = window;
const _initPostNum = _w.innerWidth > 991 ? 6 : 3;

class Project extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initTap: false,
      projectData: null,
      projectAcf: null,
      currentTab: null,
      tagPost: null,
      showPostNum: _initPostNum,
      socialData: null,
      socialTab: 'fb',
      animateTab: '',
      mainTagPost: null,
      showMainPostNum: _initPostNum,
    }
  }

  componentWillMount() {
    this._getProjectData(`project/${_w.projectId}`);

    wpApi.getPagesData(data => {
      const _filterData = data.filter(page => page.title.rendered === '設定')[0].acf;

      this.setState({
        socialData: _filterData,
        socialTab: _filterData.fb_embedded === '' ? 'ig' : 'fb',
      });
    })
  }

  componentDidUpdate() {
    if (!this.state.initTap && this.state.projectAcf && this.state.projectAcf.project_sub_title_1 && this.state.projectAcf.project_sub_title_1 !== '') {
      this.setState({ initTap: true }, () => {
        this._changeTagNum(1);
      })
    }

    if (!this.state.mainTagPost && this.state.projectAcf && this.state.projectAcf.project_tag) {
      this.setState({ mainTagPost: [] }, () => {
        this._getTagDataByTag('mainTagPost', this.state.projectAcf.project_tag);
      })
    }
  }

  _getProjectData = postType => {
    wpApi.getPostDataByType(postType, data => {
      this.setState({
        projectData: data,
        projectAcf: data.acf,
        currentTab: 1,
      }, () => this._getTagDataByTag(data.acf[`project_sub_title_1`]))
    });
  }

  _showTagPost = (postArr, num) => {
    let _tagPost = [];
    const _postArr = postArr;

    if (_postArr) {
      for (let index = 0; index < num; index++) {
        if (_postArr[index]) {
          _tagPost.push(
            <div className="content-col content-col_event" key={`seeMore${_postArr[index].date}`}>
              <a className="link_default" href={_postArr[index].link} target="_blank" rel="noopener noreferrer">
                {
                  _postArr[index]._embedded['wp:featuredmedia'] ? <div className="content-photo" style={{ backgroundImage: `url(${_postArr[index]._embedded['wp:featuredmedia'][0].source_url})` }} /> : <div className="content-photo" style={{ backgroundColor: '#efefef' }} />
                }
              </a>

              <div className="content-info">
                <p className="content-time">{service.getTimeByString(_postArr[index].date, '.')}</p>

                <a className="link_default" href={_postArr[index].link} target="_blank" rel="noopener noreferrer"><h3 className="content-colTitle content-colTitle_full" dangerouslySetInnerHTML={{ __html: _postArr[index].title.rendered }} /></a>

                <div className="content-preview content-preview_min" dangerouslySetInnerHTML={{ __html: _postArr[index].excerpt.rendered }} />
              </div>
            </div>
          )
        }
      }
    }

    return _tagPost;
  }

  _changeTagNum = num => {
    this.setState({
      tagPost: null,
      currentTab: num,
      showPostNum: _initPostNum,
      animateTab: ' fadeIn animated'
    }, () => this._getTagDataByTag('tagPost', this.state.projectAcf[`project_sub_tag_${num}`]));
  }

  _getTagDataByTag = (name, tag) => {
    wpApi.getTagsData(data => {
      const _tag = data.filter(obj => obj.name === tag)[0];

      if (_tag && _tag.id) {
        wpApi.getPostsByTagId(_tag.id, data => this.setState({
          [`${name}`]: data.sort((a, b) => new Date(b.date) - new Date(a.date))
        }));
      }
    })
  }

  render() {
    console.log(this.state)
    return (
      <Layout className="content-wrap" _temp="project">
        {
          this.state.projectData ? (
            <div className="content-hero content-hero_full">
              <div className="content-pathWrap content-pathWrap_float">
                <a className="content-pathLink" href="javascript:;">專題企劃</a>
                <a className="content-pathLink" href="javascript:;">{this.state.projectAcf.project_title}</a>
              </div>
              {
                this.state.projectData._embedded['wp:featuredmedia'] ? <div className="content-heroPhoto content-heroPhoto_full" style={{ backgroundImage: `url(${this.state.projectData._embedded['wp:featuredmedia'][0].source_url})` }}>
                  <h1 className="content-heroTitle">{this.state.projectData.title.rendered}</h1>
                </div> : <div className="content-heroPhoto content-heroPhoto_full" style={{ backgroundColor: '#efefef' }} />
              }

            </div>
          ) : null
        }
        {
          this.state.projectData ? (
            <div className="layout-container">
              <div className="content-articleWrap">
                <h1 className="content-title">{this.state.projectAcf.project_title}</h1>

                <div id="printContent" className="content-article" dangerouslySetInnerHTML={{ __html: this.state.projectAcf.project_context }} />
              </div>
            </div>
          ) : null
        }
        {
          this.state.mainTagPost && this.state.mainTagPost.length ? (
            <div className="layout-container">
              <div className="content-pageWrap project-loadMore">
                <div className="content-eventWrap">
                  {this._showTagPost(this.state.mainTagPost, this.state.showMainPostNum)}
                </div>

                {
                  this.state.showMainPostNum < this.state.mainTagPost.length ? (
                    <a className="link_loadMore" href="javascript:;" onClick={() => this.setState({ showMainPostNum: (this.state.showMainPostNum + (_w.innerWidth > 991 ? 6 : 3)) })}>
                      <img src={LoadMore} alt="LoadMore" />
                    </a>
                  ) : null
                }
              </div>
            </div>
          ) : null
        }
        {
          this.state.projectData ? (
            <div className="content-socialWrap content-socialWrap_gray">
              <p className="content-shareTitle">分享</p>

              <ShareList _title={this.state.projectAcf.project_title} />
            </div>
          ) : null
        }

        {
          this.state.projectAcf && this.state.projectAcf.project_sub_title_1 && this.state.projectAcf.project_sub_title_1 !== '' ? (
            <div className="content-pageWrap">
              <div className="project-tabWrap">
                <div className="tab-ctlWrap">
                  {
                    [1, 2, 3, 4, 5].map(index => {
                      return (
                        this.state.projectAcf[`project_sub_title_${index}`] ? <a className={`tab-link${this.state.currentTab === index ? ' active' : ''}`} href="javascript:;" key={`tabLink${index}`} onClick={() => this._changeTagNum(index)}>{`${this.state.projectAcf[`project_sub_title_${index}`]}`}</a> : null
                      )
                    })
                  }
                </div>

                <div className={`tab-wrap${this.state.animateTab}`} onAnimationEnd={() => this.setState({ animateTab: '' })}>
                  <div className="tab-content">
                    {
                      <h1 className="tab-title">{`${this.state.projectAcf[`project_sub_title_${this.state.currentTab}`]}`}</h1>
                    }
                    {
                      this.state.projectAcf[`project_sub_content_${this.state.currentTab}`] ? <div className="about-content" dangerouslySetInnerHTML={{ __html: this.state.projectAcf[`project_sub_content_${this.state.currentTab}`] }} /> : null
                    }
                  </div>
                </div>
              </div>
            </div>
          ) : null
        }
        {
          this.state.tagPost && this.state.tagPost.length ? (
            <div className="layout-container">
              <div className="content-pageWrap project-loadMore">
                <div className="content-eventWrap">
                  {this._showTagPost(this.state.tagPost, this.state.showPostNum)}
                </div>

                {
                  this.state.showPostNum < this.state.tagPost.length ? (
                    <a className="link_loadMore" href="javascript:;" onClick={() => this.setState({ showPostNum: (this.state.showPostNum + (_w.innerWidth > 991 ? 6 : 3)) })}>
                      <img src={LoadMore} alt="LoadMore" />
                    </a>
                  ) : null
                }
              </div>
            </div>
          ) : null
        }

        {
          this.state.socialData ? (
            <div className="content-rowBg content-rowBg_ig">
              <div className="layout-container">
                <div className="content-row">
                  <div className="content-social">
                    <div className="tab-ctlWrap">
                      {
                        this.state.socialData.fb_embedded ? (
                          <a className={`tab-link${this.state.socialTab === 'fb' ? ' active' : ''}`} href="javascript:;" onClick={() => this.setState({ socialTab: 'fb', animateTab: ' fadeIn animated' })}>Facebook</a>
                        ) : null
                      }
                      {
                        this.state.socialData.ig_token ? (
                          <a className={`tab-link${this.state.socialTab === 'ig' ? ' active' : ''}`} href="javascript:;" onClick={() => this.setState({ socialTab: 'ig', animateTab: ' fadeIn animated' })}>Instagram</a>
                        ) : null
                      }
                    </div>

                    <div className={`tab-wrap${this.state.animateTab}`} onAnimationEnd={() => this.setState({ animateTab: '' })}>
                      <div className="tab-content">
                        {
                          this.state.socialTab === 'fb' && this.state.socialData.fb_embedded ? (
                            <div className="content-facebook">
                              {
                                this.state.projectData.id === 156 || this.state.projectData.slug === 'taiwan-code' ? <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FKJMU.TaiwanCode%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=271493580283275" width="100%" height="500" scrolling="no" frameBorder="0" allowTransparency="true" allow="encrypted-media" title="taiwan-code"></iframe> : <iframe src={`https://www.facebook.com/plugins/page.php?href=${encodeURIComponent(this.state.socialData.fb_embedded)}&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=271493580283275`} width="100%" height="500" title="facebook-media" scrolling="no" frameBorder="0" allowTransparency="true" allow="encrypted-media"></iframe>
                              }
                            </div>
                          ) : null
                        }
                        {
                          this.state.socialTab === 'ig' && this.state.socialData.ig_token ? (
                            <InstagramLab _className="content-instagram" _accessToken={this.state.socialData.ig_token} _limit={6} />
                          ) : null
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null
        }
      </Layout>
    );
  }
}

// 準備綁定用的mapStateToProps方法，
// 將store中的items屬性綁到這個元件的props.items屬性上
const mapStateToProps = store => ({
  showMenu: store.default.showMenu,
});

// 準備綁定用的DispatchToProps方法，
// 只需要onFecthLoadItems這個方法
const mapDispatchToProps = dispatch => (
  bindActionCreators({ setGlobalUIStateByName }, dispatch)
);

// 連接Redux store
export default connect(mapStateToProps, mapDispatchToProps)(Project);
