import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Pagination } from 'antd';

import { setGlobalUIStateByName } from '../../Actions';
import { wpApi } from '../../Service';
import Layout from '../../Containers/Layout/Layout';
import './Product.scss';

const _pageSize = 6;

class Product extends Component {
  constructor(props) {
    super(props);

    this.state = {
      productData: null,
      pageNum: 1,
      articleNum: null,
    }
  }

  componentWillMount() {
    wpApi.getPostDataByType('product', data => {
      const _sortData = data.sort((a, b) => { return new Date(b.date) - new Date(a.date) });

      this.setState({
        productData: _sortData,
        articleNum: data.length
      })
    });
  }

  _getCurrentPaginationPost = num => {
    let _currentPost = [...this.state.productData];

    _currentPost = _currentPost.slice((num - 1) * _pageSize, (num - 1) * _pageSize + _pageSize);

    return _currentPost;
  }

  render() {
    return (
      <Layout className="content-wrap" _temp="product">
        <div className="layout-container">
          {
            this.state.productData ? (
              <div className="content-pageWrap">
                <div className="content-pathWrap content-pathWrap_float">
                  <a className="content-pathLink" href="javascript:;">訂購出版品</a>
                </div>

                <h1 className="content-title content-title_left">訂購出版品</h1>

                <div className="product-postWrap">
                  {
                    this._getCurrentPaginationPost(this.state.pageNum).map(post => {
                      return (
                        <div className="product-post" key={`productPost${post.link}`}>
                          <div className="product-postBorder">
                            <a className="link_default" href={post.link}>
                              {
                                post.acf && post.acf.product_img_1 ? <div className="product-postPhoto" style={{ backgroundImage: `url(${post.acf.product_img_1})` }} /> : <div className="product-postPhoto" style={{ backgroundColor: '#efefef' }} />
                              }
                            </a>

                            <div className="product-postInfo">
                              <a className="link_default" href={post.link}>
                                <h1 className="product-postTitle" dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
                              </a>

                              <p className="product-postText" dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>

                <Pagination current={this.state.pageNum} total={this.state.articleNum} defaultPageSize={_pageSize} hideOnSinglePage={true} onChange={val => this.setState({ pageNum: val })} />
              </div>
            ) : null
          }
        </div>
      </Layout >
    );
  }
}

// 準備綁定用的mapStateToProps方法，
// 將store中的items屬性綁到這個元件的props.items屬性上
const mapStateToProps = store => ({
  showMenu: store.default.showMenu,
});

// 準備綁定用的DispatchToProps方法，
// 只需要onFecthLoadItems這個方法
const mapDispatchToProps = dispatch => (
  bindActionCreators({ setGlobalUIStateByName }, dispatch)
);

// 連接Redux store
export default connect(mapStateToProps, mapDispatchToProps)(Product);
