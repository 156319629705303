import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { setGlobalUIStateByName } from '../../Actions';
import Layout from '../../Containers/Layout/Layout';
import './Form.scss';

class Form extends Component {
  render() {
    return (
      <Layout className="content-wrap content-wrap_form" _temp="form" />
    );
  }
}

// 準備綁定用的mapStateToProps方法，
// 將store中的items屬性綁到這個元件的props.items屬性上
const mapStateToProps = store => ({
  showMenu: store.default.showMenu,
});

// 準備綁定用的DispatchToProps方法，
// 只需要onFecthLoadItems這個方法
const mapDispatchToProps = dispatch => (
  bindActionCreators({ setGlobalUIStateByName }, dispatch)
);

// 連接Redux store
export default connect(mapStateToProps, mapDispatchToProps)(Form);
