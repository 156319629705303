import * as parseUrl from './parseUrl';

export const employeeNo = parseUrl.serverEnv() === 'dev' || parseUrl.serverEnv() === 'local' ? [/(^[0-9A-Za-z])/i, '9'] : [/(^[hH][0-9]{5})/i, '6'];
// /(^100[0-9]{5}[tT])/i

export const discountRulesArray = {
  EVT019: {
    required: true,
    requiredMessage: '請輸入員購卡序號',
    pattern: /(^201[0-9A-Za-z]{7})/i,
    patternMessage: '員購卡序號格式錯誤，請重新輸入',
    maxLength: '10'
  },
  EVT020: {
    required: true,
    requiredMessage: '請輸入親友卡序號',
    pattern: /(^B[0-9]{1,8})/i,
    patternMessage: '親友卡序號格式錯誤，請重新輸入',
    maxLength: '9'
  },
  EVT078: {
    required: true,
    requiredMessage: '請輸入媒體公關卡序號',
    pattern: /(^201[0-9A-Za-z]{7})/i,
    patternMessage: '媒體公關卡序號格式錯誤，請重新輸入',
    maxLength: '10'
  },
}

export const FarEasTonePattern = /(^[0-9A-Za-z]{9})/i;