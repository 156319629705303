// @ flow
import {
  SET_GLOBALUI_STATE_BY_NAME,
} from '../Actions';

const initialState = {
  showMenu: null,
  loadingAnimate: false,
};

export default function GlobalUI(
  state = initialState,
  action: Object
) {
  switch (action.type) {
    case SET_GLOBALUI_STATE_BY_NAME:
      {
        let newObj = { ...state };
        if (action.payload.val === '') {
          newObj[action.payload.name] = !newObj[action.payload.name];
        } else {
          newObj[action.payload.name] = action.payload.val;
        }

        return newObj;
      }

    default:
      return state;
  }
}
